import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { employee_pages } from '../pages/employee-pages';
import { Layouts } from '../utils/enums';
import { Layout, Tag } from 'antd';
import { Headers, Siders } from '../components';
import { AiOutlineMenuUnfold, AiOutlineMenuFold } from 'react-icons/ai'
import { Home } from '../pages/employee-pages/home';


const Employee = ({ history }) => {
    const { Content } = Layout;
    const [collapsed, setCollapsed] = React.useState(true);

    const getRoutes = (defaultRoutes: any[]) =>
        defaultRoutes.map(prop => {
            if (prop.layout === Layouts.Employeee) {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        exact={prop.exact}
                        component={prop.component}
                        key={prop.path}
                    />
                );
            }
            return null;
        });

    return (
        <main id="employee">
            <Headers.Mobile routes={employee_pages} />
            <Layout>
                <Siders.Main layout={Layouts.Employeee} collapsed={collapsed} routes={employee_pages} />
                <Content className="content">
                    <div style={{ display: 'flex', backgroundColor: '#fff' }}>
                        {React.createElement(collapsed ? AiOutlineMenuUnfold : AiOutlineMenuFold, {
                            className: 'menu-trigger',
                            onClick: () => setCollapsed(!collapsed)
                        })}
                        <Headers.Main history={history} />
                    </div>
                    <Switch>
                        {getRoutes(employee_pages)}
                        <Route
                            path={'/employee/join/:orgCode'}
                            exact={true}
                            component={() => <Home />}
                        />
                    </Switch>
                </Content>
            </Layout>
        </main>
    );
};

export default Employee;
