import React from 'react';
import { Modal, Button, Input, message, DatePicker } from 'antd';
import moment from 'moment'
import { APP_URL } from '../../config/axios'
import './style.less'
import { AxiosError } from 'axios';
import { useAuthContext } from '../../context/AuthContext';

type ModalProps = {
    visible: boolean,
    setVisible: Function,
    setReviewSchedule: Function,
    reviewSchedule: any
}

const ScheduleModal = ({ visible, setVisible, setReviewSchedule, reviewSchedule }: ModalProps) => {
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [scheduleTitle, setScheduleTitle] = React.useState('');
    const [scheduleDate, setScheduleDate] = React.useState('');
    const { authKey, currentUser } = useAuthContext();

    const postSchedule = () => {

        setConfirmLoading(true);

        let scheduleData = {
            title: scheduleTitle,
            date: scheduleDate,
            createdBy: `${currentUser.firstName} ${currentUser.lastName}`,
            active: false
        }

        if (scheduleTitle.length < 1) {
            message.warning("Please add Title")
        }
        else if (scheduleDate === null) {
            message.warning("Please select a review date")
        }

        else {

            APP_URL.post("reviewschedule", scheduleData, {
                headers:
                    { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                setReviewSchedule()
                setVisible(false);
                setConfirmLoading(false);
                message.success("Review schedule added successfully")
                setScheduleTitle("")
                setScheduleDate("")
            }).catch((err: AxiosError) => {
                console.log(err.message)
                message.error("There was an error adding review schedule")
            })
        }
    }
    const putSchedule = () => {

        setConfirmLoading(true);
        reviewSchedule.title = scheduleTitle
        reviewSchedule.date = scheduleDate

        if (scheduleTitle.length < 1) {
            message.warning("Please add Title")
        }
        else {
            APP_URL.put(`reviewschedule/${reviewSchedule.id}`, reviewSchedule, {
                headers:
                    { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                message.success("Schedule updated successfully")
                setReviewSchedule()
                setVisible(false);
                setConfirmLoading(false);
                setScheduleTitle("")
                setScheduleDate("")
            }).catch((err: AxiosError) => {
                console.log(err.message)
                message.error("There was an error updating review schedule")
            })
        }
    }

    const handleOk = () => {
        if (reviewSchedule === null) {
            postSchedule()
        }
        else {
            putSchedule()
        }
    };

    const handleDateChange = (date, dateString) => {
        setScheduleDate(dateString)
    }


    React.useEffect(() => {
        if (reviewSchedule !== null) {
            setScheduleTitle(reviewSchedule.title)
            setScheduleDate(reviewSchedule.date)
        }
        else {
            setScheduleTitle('')
            setScheduleDate(null)
        }
    }, [reviewSchedule])

    const handleCancel = () => {
        setVisible(false);
        setConfirmLoading(false)
    };

    return (
        <>
            <Modal
                title="Create a Scheduled Review"
                visible={visible}
                className="data-input-modal"
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText={"Schedule added"}
                footer={[
                    <Button key="back" className="cancel-btn" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" className="reg-btn" type="primary" loading={confirmLoading} onClick={handleOk}>
                        Continue
                    </Button>,
                ]}
            >
                <div>
                    <div>Schedule Title:</div> <Input className='modal-data-input' value={scheduleTitle} onChange={(val) => setScheduleTitle(val.target.value)} placeholder="January Reviews"></Input>
                    <div>
                        <div>Schedule Date:</div>
                        <DatePicker className='modal-select' value={scheduleDate !== null ? moment(scheduleDate) : moment()} onChange={handleDateChange} />
                    </div>
                </div>

            </Modal>
        </>
    )
}

export default ScheduleModal;
