import React, { useState } from 'react';
import './style.less';
import { Avatar, Table, Button, message } from 'antd'
import { APP_URL } from '../../../config/axios'
import { useAuthContext } from '../../../context/AuthContext';
import { useReviewContext } from '../../../context/ReviewsContext';
import { AxiosError } from 'axios';
import { Drawers, Spinners } from '../../../components'
import { HiOutlineCheckCircle } from 'react-icons/hi';
import moment from 'moment';
import { CompassOutlined } from '@ant-design/icons';

export const Reviews = () => {
  const [loading, setLoading] = React.useState(false)
  const { authKey, currentUser } = useAuthContext();
  const { globalPendingReviews, setGlobalPendingReview, globalReports, setGlobalReports, setGlobalUserEmp, globalUserEmp } = useReviewContext();
  const [selectedReview, setSelectedReview] = React.useState(null)
  const [revDrawerVisible, setRevDrawerVisible] = React.useState(false)
  const [managerArray, setManagerArray] = React.useState([])
  const [pendingReviews, setPendingReviews] = React.useState([])
  const [selfRevArray, setSelfRevArray] = React.useState([])
  const [reportsRevArray, setReportsRevArray] = React.useState([])
  const [currentUserEmployee, setCurrentUserEmployee] = React.useState(null);
  const [currentSelfReview, setCurrentSelfReview] = React.useState(null)
  const [selectedResponse, setSelectedResponse] = React.useState(null)
  const [yourRatings, setYourRatings] = React.useState([])
  const [otherRatings, setOtherRatings] = React.useState([])
  const [drawerReportVisible, setDrawerReportVisible] = React.useState(false)
  const [surveys, setSurveys] = React.useState(null);
  const [reviewSummaryComment, setReviewSummaryComment] = React.useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [reviewedId, setReviewedId] = React.useState(null);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  }

  const isMobile = () => width <= 768;

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);


  const getDisplayPic = (record) => {
    if (record.profilePic !== null) { return String(record.profilePic); }
    else { return `https://ui-avatars.com/api/?name=${record.firstName + ' ' + record.lastName}&background=59394A&color=fff&size=256` }
  }

  const data = {
    labels: ['Courage', 'Humility', 'Adventure', 'Initiative', 'Resilience'],

    datasets: [

      {
        label: 'Your Rating',
        backgroundColor: '#CED8D9',
        borderColor: '#CED8D9',
        borderWidth: 1,
        hoverBackgroundColor: '#E0E9EA',
        hoverBorderColor: '#E0E9EA',
        data: yourRatings
      },
      {
        label: `Other's Rating`,
        backgroundColor: '#F9F3EF',
        borderColor: '#F9F3EF',
        borderWidth: 1,
        hoverBackgroundColor: '#FFF7F1',
        hoverBorderColor: '#FFF7F1',
        data: otherRatings
      }
    ]
  };
  // filter list of surey for date createdAt field
  const findSurveyCreatedAt = (surveyId) => {
    if (surveys) {
      const _survey = surveys.find((x) => x.id === surveyId);
      if (_survey) {
        return _survey['createdAt'];
      }
    }
  }

  const employeeTableData = [
    {
      dataIndex: 'profilePic',
      width: 90,
      key: 'profilePic',
      render: (text, record) => <div><Avatar size="default" className='table-avatar' src={getDisplayPic(record)} /></div>
    },
    {
      title: <div className="user-table-head">Name</div>,
      dataIndex: 'firstName',
      // width: 250,
      key: 'firstName',
      render: (text, record) => <div>{text} {record.lastName}</div>
    },
    {
      title: <div className="user-table-head">Email</div>,
      dataIndex: 'email',
      key: 'email',
      // width: 350,
      ellipses: true
    },
    {
      title: <div className="user-table-head">Survey</div>,
      dataIndex: 'surveyTitle',
      key: 'surveyTitle',
      // width: 350,
      ellipses: true
    },

    {
      dataIndex: 'completed',
      key: 'completed',
      title: <div className="user-table-head">Action</div>,

      render: (t, record) => (
        <>
          {t === false &&
            <Button onClick={() => displayReviewDrawer(record)} className='edit-q'> Review Now</Button>
          }
          {
            t === true &&
            <div className='completed-review-options'>
              <Button icon={<HiOutlineCheckCircle className='btn-tick-icon' />} className='completed-q'> Completed</Button>
              <Button className='view-btn' style={{ marginLeft: '8px' }} onClick={() => getSelfReview(record)} >View</Button>
            </div>
          }
        </>)
    },
  ];

  const managerTableData = [
    {
      dataIndex: 'profilePic',
      width: 90,
      key: 'profilePic',
      render: (text, record) => <div><Avatar size="default" className='table-avatar' src={getDisplayPic(record)} /></div>
    },
    {
      title: <div className="user-table-head">Name</div>,
      dataIndex: 'firstName',
      // width: 250,
      key: 'firstName',
      render: (text, record) => <div>{text} {record.lastName}</div>
    },

    {
      title: <div className="user-table-head">Email</div>,
      dataIndex: 'email',
      // width: 350,
      key: 'email',
      ellipses: true
    },
    {
      title: <div className="user-table-head">Survey</div>,
      dataIndex: 'surveyTitle',
      key: 'surveyTitle',
      // width: 350,
      ellipses: true
    },

    {
      dataIndex: 'completed',
      key: 'completed',
      title: <div className="user-table-head">Action</div>,

      render: (t, record) => (
        <>
          {t === false &&
            <Button onClick={() => displayReviewDrawer(record)} className='edit-q'> Review Now</Button>
          }
          {
            t === true &&
            <div className='completed-review-options'>
              <Button icon={<HiOutlineCheckCircle className='btn-tick-icon' />} className='completed-q'> Completed</Button>
              <Button className='view-btn' style={{ marginLeft: '8px' }} onClick={() => getSelfReview(record)} >View</Button>
            </div>
          }
        </>)
    },

  ];

  const selfReviewTableData = [
    {
      dataIndex: 'profilePic',
      width: 90,
      key: 'profilePic',
      render: (text, record) => <div><Avatar className='table-avatar-mobile' src={getDisplayPic(record)} /></div>
    },
    {
      title: <div className="user-table-head">Name</div>,
      dataIndex: 'firstName',
      // width: 250,
      key: 'firstName',
      render: (text, record) => <div>{text} {record.lastName}</div>
    },
    {
      title: <div className="user-table-head">Email</div>,
      dataIndex: 'email',
      // width: 350,
      key: 'email',
      ellipses: true
    },
    {
      title: <div className="user-table-head">Survey</div>,
      dataIndex: 'surveyTitle',
      key: 'surveyTitle',
      // width: 350,
      ellipses: true
    },

    {
      dataIndex: 'completed',
      key: 'completed',
      title: <div className="user-table-head">Action</div>,

      render: (t, record) => (
        <>
          {t === false &&
            <Button onClick={() => displayReviewDrawer(record)} className='edit-q'> Review Now</Button>
          }
          {
            t === true &&
            <div className='completed-review-options'>
              <Button icon={<HiOutlineCheckCircle className='btn-tick-icon' />} className='completed-q'> Completed</Button>
            </div>
          }
        </>)
    },

  ];

  const mobileReviewTableData = [
    {
      dataIndex: 'profilePic',
      key: 'profilePic',
      render: (text, record) => <div><Avatar size='default' className='table-avatar-mobile' src={getDisplayPic(record)} /></div>
    },
    {
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text, record) => <div className='table-text-mobile'>{text} {record.lastName}</div>
    },
    {
      dataIndex: 'surveyId',
      key: 'surveyId',
      render: (id, record) => <div className='table-text-mobile'>{moment(findSurveyCreatedAt(id)).fromNow()}</div>
    },
    {
      dataIndex: 'completed',
      key: 'completed',

      render: (t, record) => (
        <>
          {t === false &&
            <Button onClick={() => displayReviewDrawer(record)} className='edit-q edit-q-mobile'>Review Now</Button>
          }
          {
            t === true &&
            <div className='completed-review-options'>
              {/* <Button icon={<HiOutlineCheckCircle className='btn-tick-icon' />} className='completed-q completed-q-mobile'> Completed</Button> */}
              <Button className='view-btn' style={{ marginLeft: '8px' }} onClick={() => getSelfReview(record)} >View</Button>
            </div>
          }
        </>)
    },

  ];

  const displayReviewDrawer = (record) => {
    setSelectedReview(record)
    setRevDrawerVisible(true)
  }

  const getSurveyResponses = (revList, currId) => {
    APP_URL.get(`surveyresponse`,
      {
        headers: { "Authorization": `Basic ${authKey}` }
      }).then(res => {
        if (res.status === 200) {
          revList.map(surv => {
            //self
            if (res.data.filter(sr => (sr.reviewerId === currId) && (sr.reviewedId === currId) && (surv.reviewedId === currId) && (surv.reviewScheduleId === sr.reviewScheduleId)).length > 0) {
              surv.completed = true
            }
            else if (res.data.filter(sr => (sr.reviewerId === currId) && (sr.reviewedId === surv.reviewedId) && (surv.reviewScheduleId === sr.reviewScheduleId)).length > 0) {
              surv.completed = true
            }
            else {
              surv.completed = false
            }
          })
          setPendingReviews(revList)
          if (globalReports.length === 0) {
            setGlobalReports(res.data.filter(x => x.reviewerId === currId));
          }
        }
      }).catch((err: AxiosError) => {
        if (err.response) {
          console.log(err.message)
        }
      })
  }

  React.useEffect(() => {
    if (globalReports.length > 0) {
      // console.log(globalReports)
    }
console.log(reviewedId)
  }, [reviewedId])


  const getPendingReviews = () => {
    setLoading(true)
    APP_URL.get(`userauth/${currentUser.id}/employee`,
      {
        headers: { "Authorization": `Basic ${authKey}` }
      }).then(user => {
        if (user.status === 200) {
          //user exists
          if (user.data.employees.length > 0) {
            //User has employee record
            setCurrentUserEmployee(user.data.employees[0])
            setGlobalUserEmp(user.data.employees[0])
            let data = { employeeId: user.data.employees[0].id }
            console.log(data)

            APP_URL.post("reviewschedule/pendingReviews", data, {
              headers: { "Authorization": `Basic ${authKey}` }
            }).then(rev => {
              if (rev.status === 200) {

                setLoading(false)
                getSurveyResponses(rev.data, Number(user.data.employees[0].id))
              }
              else {
                setLoading(false)
              }
            }).catch((err: AxiosError) => {
              if (err.response) {
                setLoading(false)
                console.log(err.message)
              }
            })
          }
          else {
            //no employee record
            setLoading(false)
          }
        }

        else {
          //user not found
          setLoading(false)
        }
      }).catch((err: AxiosError) => {
        if (err.response) {
          setLoading(false)
          console.log(err.message)
        }
      })
  }

  const getSelfReview = (employee) => {
    const _surveyResponses = globalReports.filter(x => x.reviewedId === employee.reviewedId && x.reviewScheduleId === employee.reviewScheduleId);

    console.log(globalReports)
    console.log(globalUserEmp, employee)
    setReviewedId(employee.reviewedId)
    console.log(employee.reviewedId)
    let _selectedResponse = _surveyResponses.filter(x => x.reviewerId === globalUserEmp?.id)[0];

    // if (_selfReview) {
      if (_selectedResponse) {
        _selectedResponse['reviewerInfo'] = employee;
        if (!(Array.isArray(_selectedResponse.responseJson))) {
          _selectedResponse.responseJson = JSON.parse(_selectedResponse.responseJson)
        }
        // if (!(Array.isArray(_selfReview.responseJson))) {
        //   _selfReview.responseJson = JSON.parse(_selfReview.responseJson)
        // }

        _selectedResponse.responseJson = _selectedResponse.responseJson.sort((a, b) => (a.order) - (b.order));
        // _selfReview.responseJson = _selfReview.responseJson.sort((a, b) => (a.order) - (b.order));
        setSelectedResponse(_selectedResponse)

        setDrawerReportVisible(true)
      }
      else {
        message.error(employee.firstName + " needs to complete their to view results")
      }
  }

  const getReviewComment = (response, reviewer, reviewed) => {
    let _reviewComment = response.reviewComments
    if (_reviewComment.length === 0) {
      _reviewComment = {
        "comment": null,
        "rating": null,
        "reviewerId": reviewer.id,
        "reviewedId": reviewed.reviewedId,
        "surveyResponseId": response.id,
      }
      setReviewSummaryComment(_reviewComment);
    } else {
      _reviewComment[0].shouldUpdate = true;
      setReviewSummaryComment(_reviewComment[0]);
    }
  }


  const getSurveys = () => {
    APP_URL.get(`survey`,
      {
        headers: { "Authorization": `Basic ${authKey}` }
      }).then(surveys => {
        setSurveys(surveys.data);
      }).catch((err: AxiosError) => {
        if (err.response) {
          console.log(err.message)
        }
      })
  }

  React.useEffect(() => {
    if (!surveys) {
      getSurveys();
    }

  }, [surveys])

  React.useEffect(() => {

    if (currentUser !== null && authKey !== null) {
      if (globalPendingReviews && globalPendingReviews.length > 0) {
        console.log("globalPendingReviews: ", globalPendingReviews)
        setPendingReviews(globalPendingReviews)
        setManagerArray(globalPendingReviews.filter(revItem => revItem.formType === "manager"))
        setSelfRevArray(globalPendingReviews.filter(revItem => revItem.formType === "self"))
        setReportsRevArray(globalPendingReviews.filter(revItem => revItem.formType === "employee"))
      } else {
        getPendingReviews()
        // getSurveys()
      }

    }
  }, [currentUser])

  React.useEffect(() => {

    if (pendingReviews.length > 0) {

      setGlobalPendingReview(pendingReviews)
      setManagerArray(pendingReviews.filter(revItem => revItem.formType === "manager"))
      setSelfRevArray(pendingReviews.filter(revItem => revItem.formType === "self"))
      setReportsRevArray(pendingReviews.filter(revItem => revItem.formType === "employee"))

    }
  }, [pendingReviews])

  return <Spinners.CustomSpinner tip={"Getting reviews"} spinning={loading}>
    <main id="reviews-root">

      <div className='pending-rev'>
        Pending Reviews
    </div>

      {
        pendingReviews.length > 0 ?
          <>

            <div className='rev-msg'>
              Welcome to your quarterly review. In the next two weeks, you need to complete the following surveys for your direct reports and managers.
            <div className='rev-next-steps' style={{ marginTop: '10px' }}>1) Fill out the evaluation for each employee below. This should take you 10 minutes each</div>
              <div className='rev-next-steps' style={{ margin: '5px 0' }}>2) Schedule a one-on-one meeting with direct report to discuss the feedback</div>
              <div className='rev-next-steps'>3) Send a note to the people team with insights and recommendations from your conversation</div>
            </div>
            <div className='rev-table-contain' >
              <div className='rev-table-label'>Self Review</div>
              <Table
                className='review-table'
                // rowClassName={(record, index) => isMobile ? (index % 2 === 0 ? 'table-row-light' : 'table-row-dark') : ''}
                rowKey="id"
                columns={isMobile() ? mobileReviewTableData : selfReviewTableData}
                dataSource={selfRevArray}
              />
            </div>

            <div className='rev-table-contain' >
              <div className='rev-table-label'>Direct Reports</div>
              <Table
                className='review-table'
                // rowClassName={(record, index) => isMobile ? (index % 2 === 0 ? 'table-row-light' : 'table-row-dark') : ''}
                rowKey="id"
                columns={isMobile() ? mobileReviewTableData : employeeTableData}
                dataSource={reportsRevArray}
              />
            </div>

            <div className='rev-table-contain'>
              <div className='rev-table-label'>Manager Review</div>
              <Table
                className='review-table'
                // rowClassName={(record, index) => isMobile ? (index % 2 === 0 ? 'table-row-light' : 'table-row-dark') : ''}
                rowKey="id"
                columns={isMobile() ? mobileReviewTableData : managerTableData}
                dataSource={managerArray}
              />
            </div>
          </>
          :
          <div className='empty-review'>
            There are no scheduled reviews at the moment
        </div>

      }
      <Drawers.ReviewDrawer visible={revDrawerVisible} selectedReview={selectedReview} reviewsList={pendingReviews.filter(revItem => revItem.completed === false)} setVisible={setRevDrawerVisible} />
      <Drawers.ChartReportDrawer 
        visible={drawerReportVisible} 
        setVisible={setDrawerReportVisible} 
        selectedResponse={selectedResponse} 
        name={selectedResponse?.reviewerInfo?.firstName} 
        reviewComment={reviewSummaryComment} 
        reviewedId={reviewedId}
        inverse={true} />

    </main >
  </Spinners.CustomSpinner >
};