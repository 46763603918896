import React from 'react';
import { User } from '../models/User';

interface IAuthContext {
  isLogged?: boolean;
  setIsLogged?: any;
  authKey?: any;
  setAuthKey?: any;
  authenticateUser?(authKey, user): any
  currentUser?: User;
  setCurrentUser?: any;
}

export const AuthContext = React.createContext<IAuthContext>({});

export const useAuthContext = () => React.useContext(AuthContext);

export const AuthProvider = ({ children }: any) => {
  const [isLogged, setIsLogged] = React.useState(false);
  const [authKey, setAuthKey] = React.useState("");
  const [currentUser, setCurrentUser] = React.useState(null);

  const authenticateUser = (authKey: string, user: User) => {
    localStorage.setItem('authKey', authKey)
    setAuthKey(authKey)
    setIsLogged(true)
    setCurrentUser(user)
  }

  return (
    <AuthContext.Provider value={{ isLogged, setIsLogged, authenticateUser, authKey, setAuthKey, currentUser, setCurrentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
