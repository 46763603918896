import React from 'react';
import { Avatar, Input } from 'antd'
import { AxiosError } from 'axios';
import moment from 'moment'
import './style.less';
import { useAuthContext } from '../../../context/AuthContext';
import { useReviewContext } from '../../../context/ReviewsContext';
import { APP_URL } from '../../../config/axios'

export const Profile = () => {
  const { authKey, currentUser } = useAuthContext();
  const { globalUserEmp, setGlobalUserEmp } = useReviewContext();
  const [userEmp, setUserEmp] = React.useState(null)
  const [roleName, setRoleName] = React.useState('')
  const [deptName, setDeptName] = React.useState('')


  const getUserEmp = () => {
    APP_URL.get(`userauth/${currentUser.id}/employee`,
      {
        headers: { "Authorization": `Basic ${authKey}` }
      }).then(user => {
        setUserEmp(user.data.employees[0])
      }).catch((err: AxiosError) => {
        if (err.response) {
          console.log(err.message)
        }
      })
  }
  const getRole = (emp) => {
    APP_URL.get(`role/${emp.roleId}`,
      {
        headers: { "Authorization": `Basic ${authKey}` }
      }).then(response => {
        setRoleName(response.data.roleName)
      }).catch((err: AxiosError) => {
        if (err.response) {
          console.log(err.message)
        }
      })
  }
  const getMegaTribe = (emp) => {
    APP_URL.get(`department/${emp.deptId}`,
      {
        headers: { "Authorization": `Basic ${authKey}` }
      }).then(mt => {
        setDeptName(mt.data.deptName)
      }).catch((err: AxiosError) => {
        if (err.response) {
          console.log(err.message)
        }
      })
  }

  React.useEffect(() => {
    if (globalUserEmp) {
      setUserEmp(globalUserEmp)
    }
    else {
      if (currentUser) {
        getUserEmp()
      }
    }

  }, [currentUser])

  React.useEffect(() => {
    if (userEmp) {
      getRole(userEmp)
      getMegaTribe(userEmp)
    }
  }, [userEmp])

  return <main id="profile-root">

    <div>
      <div className='profile-top-shadow'>
        <Avatar src={userEmp && userEmp.profilePic} className='profile-avatar' />
        <div className='profile-name'>{userEmp && userEmp.firstname} {userEmp && userEmp.lastname}</div>
        <div className='profile-role'>{roleName}</div>
      </div>
      <div className='profile-inputs'>
        <div className='input-row'>
          <div className='input-div' style={{ width: '45%' }}>
            <span className='profile-label'>Email</span>
            <Input value={userEmp && userEmp.email} className='profile-input'></Input>
          </div>

          <div className='input-div' style={{ width: '45%' }}>
            <span className='profile-label'>Department</span>
            <Input value={deptName} className='profile-input'></Input>
          </div>
        </div>
        <div className='input-row'>
          <div className='input-div' style={{ width: '90%' }}>
            <span className='profile-label'>Role</span>
            <Input value={roleName} className='profile-input'></Input>
          </div>
        </div>

        <div className='input-row'>
          <div className='input-row-right'>
            <div className='input-div' style={{ width: '10%' }}>
              <span className='profile-label'>Start Date</span>
              <Input value={moment(userEmp && userEmp.startDate).format('MMM')} className='profile-input'></Input>
            </div>
            <div className='input-div' style={{ width: '10%' }}>
              <span className='profile-label'><br /></span>
              <Input value={moment(userEmp && userEmp.startDate).format('yyyy')} className='profile-input'></Input>
            </div>
          </div>
        </div>
      </div>
    </div>


  </main>;
};
