import React from 'react';
import { logo, theroom_logo } from '../../assets';
import { Button } from 'antd';
import { GoogleOutlined, WindowsFilled } from '@ant-design/icons';
import GoogleLogin from 'react-google-login';
import MicrosoftLogin from "react-microsoft-login";
import { APP_URL } from '../../config/axios'
import './style.less';
import { useAuthContext } from '../../context/AuthContext';
import { AxiosError } from 'axios';
import { Spinners } from '../../components'
import { Modals } from '../../components'
import { User } from '../../models/User'
import { useParams } from 'react-router-dom';


export const Landing = ({ history }) => {

  const { authenticateUser } = useAuthContext();
  const [loading, setLoading] = React.useState(false)
  const [userData, setUserData] = React.useState({})
  const [visible, setVisible] = React.useState(false)
  const [isCreateModalVisible, setIsCreateModalVisible] = React.useState(false)
  const [isJoinModalVisible, setIsJoinModalVisible] = React.useState(false)
  const { orgCode } = useParams<any>()

  const responseMicrosoft = async (err, response) => {
    console.log(err, response);
    if (!err) {
      let lastName = response.surname;
      let firstName = response.givenName;
      let authSystemId = response.id;
      let email = response.account.userName;
      let profilePic = `https://ui-avatars.com/api/?name=${response.givenName}+${response.surname}`;

      setLoading(true)

      APP_URL.get("userauth/authorize-user",
        {
          headers:
            { "Authorization": "Basic " + btoa(`${email}:${authSystemId}`) }
        })
        .then(res => {
          if (res.status === 200) {
            authenticateUser(btoa(`${email}:${authSystemId}`), res.data);
            if (!!orgCode) {
              history.push(`/employee/join/${orgCode}`);
            } else {
              history.push('/employee/home');
            }
            setLoading(false)
          }
        })
        .catch((err: AxiosError) => {
          setLoading(false)
          if (err.response) {
            if (err.response.status === 401) {
              console.log("account not found, please create an account")

              let userData: User = { firstName: firstName, lastName: lastName, email: email, userType: 1, authSystemId: authSystemId, authType: "Microsoft", profilePic: profilePic }
              setUserData(userData)
              setVisible(true)
            }
            else {
              console.log(err.message)
            }
          }
        })
    }


  };

  const responseGoogle = async (response) => {

    let lastName = response.profileObj.familyName;
    let firstName = response.profileObj.givenName;
    let authSystemId = response.profileObj.googleId;
    let email = response.profileObj.email;
    let profilePic = response.profileObj.imageUrl;

    setLoading(true)

    APP_URL.get("userauth/authorize-user",
      {
        headers:
          { "Authorization": "Basic " + btoa(`${email}:${authSystemId}`) }
      })
      .then(res => {
        if (res.status === 200) {
          authenticateUser(btoa(`${email}:${authSystemId}`), res.data);
          if (!!orgCode) {
            history.push(`/employee/join/${orgCode}`);
          } else {
            history.push('/employee/home');
          }
          setLoading(false)
        }
      })
      .catch((err: AxiosError) => {
        setLoading(false)
        if (err.response) {
          if (err.response.status === 401) {
            console.log("unathorized, please create an account")

            let userData: User = { firstName: firstName, lastName: lastName, email: email, userType: 1, authSystemId: authSystemId, authType: "Google", profilePic: profilePic }
            setUserData(userData)
            setVisible(true)
          }
          else {
            console.log(err.message)
          }
        }
      })
  }

  const responseGoogleFail = (err) => { console.log(err) }

  return (
    <Spinners.CustomSpinner tip={"Authenticating"} spinning={loading}>
      <main className="container ">

        <aside>
          <div className="branding">
            {/* <div className='branding-images'>
              <img src={logo} alt="alx-brand-logo" />
              <img className='the-room-logo' src={theroom_logo} alt="alx-brand-logo" />
            </div> */}

            <h6>Team reviews redefined</h6>
          </div>

          {/*TODO: Hide client-id*/}
          <div className="form-container">
            <GoogleLogin
              clientId="948172179633-06sdssdmg3urq7b930f0b7u8re4udghs.apps.googleusercontent.com"
              buttonText="Sign in with Google"
              onSuccess={responseGoogle}
              onFailure={responseGoogleFail}
              cookiePolicy={'single_host_origin'}
              render={renderProps => (
                <Button onClick={renderProps.onClick} >
                  <GoogleOutlined /> Sign in with Google
                </Button>
              )}
            />
            <div className='login-contain'>
              <MicrosoftLogin children={<Button className='login-btn-microsoft' icon={<WindowsFilled />} size='middle'>Sign in with Microsoft </Button>}
                withUserData={true} clientId={'ca457e97-09b0-49e4-ac26-094cdb973ffd'}
                authCallback={responseMicrosoft} />
            </div>

          </div>
        </aside>

        <div className="image-container" />
      </main>
      <Modals.RegisterModal userData={userData} history={history} visible={visible} setVisible={setVisible} setIsCreateModalVisible={setIsCreateModalVisible} setIsJoinModalVisible={setIsJoinModalVisible} />
      <Modals.CreateOrganisationModal userData={userData} visible={isCreateModalVisible} setVisible={setIsCreateModalVisible} setIsRegisterModalVisible={setVisible} />
      <Modals.JoinOrganisationModal orgCode={orgCode} userData={userData} visible={isJoinModalVisible} setVisible={setIsJoinModalVisible} setIsRegisterModalVisible={setVisible} />
    </Spinners.CustomSpinner>
  );
};
