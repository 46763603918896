import React from 'react';
import { Modal, Button, Input, message } from 'antd';
import { APP_URL } from '../../config/axios'
import './style.less'
import { AxiosError } from 'axios';
import { useAuthContext } from '../../context/AuthContext';
import { useReviewContext } from '../../context/ReviewsContext';

type ModalProps = {
    visible: boolean,
    setVisible: Function,
    setRole: Function,
    role: any
}

const RoleModal = ({ visible, setVisible, setRole, role }: ModalProps) => {
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [roleName, setRoleName] = React.useState('');
    const [roleDesc, setRoleDesc] = React.useState('');
    const { authKey } = useAuthContext();
    const { globalOrg } = useReviewContext();

    React.useEffect(() => {
        if (role !== null) {
            setRoleName(role.roleName)
            setRoleDesc(role.description)

        }
        else {
            setRoleName('')
            setRoleDesc('')
        }

    }, [role])

    const postRole = () => {
        setConfirmLoading(true);

        let roleData = {
            roleName: roleName,
            description: roleDesc,
            orgId: globalOrg.id
        }

        if (roleName.length < 1) {
            message.warning("please enter role name")
        } else {
            APP_URL.post("role", roleData, {
                headers:
                    { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                setRole(res.data)
                setVisible(false);
                setConfirmLoading(false);
                message.success("Role added successfully")
                setRoleName("")
                setRoleDesc("")
            }).catch((err: AxiosError) => {
                console.log(err.message)
                message.error("There was an error adding role")
                setConfirmLoading(false);
            })
        }
    }

    const putRole = () => {
        setConfirmLoading(true);

        role.roleName = roleName
        role.description = roleDesc

        if (roleName.length < 1) {
            message.warning("please enter role name")
        }
        else {
            APP_URL.put(`role/${role.id}`, role, {
                headers:
                    { "Authorization": "Basic " + `${authKey}` }
            }).then(res => {
                setRole(null)
                setVisible(false);
                setConfirmLoading(false);
                message.success("role updated successfully")
                setRoleName("")
                setRoleDesc("")
            }).catch((err: AxiosError) => {
                console.log(err.message)
                message.error("Something went wrong updating the role")
            })
        }
    }

    const handleOk = () => {
        if (role === null) {
            postRole()
        }
        else {
            putRole()
        }

    };

    const handleCancel = () => {
        setVisible(false);
        setConfirmLoading(false)
    };

    return (
        <>
            <Modal
                title="Create a Role"
                visible={visible}
                className="data-input-modal"
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText={"Role added"}
                footer={[
                    <Button key="back" className="cancel-btn" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" className="reg-btn" type="primary" loading={confirmLoading} onClick={handleOk}>
                        Continue
                    </Button>,
                ]}
            >
                <div>
                    <div>Role Name:</div> <Input className='modal-data-input' value={roleName} onChange={(val) => setRoleName(val.target.value)} placeholder="Manager"></Input>
                    <div>Description:</div> <Input className='modal-data-input' value={roleDesc} onChange={(val) => setRoleDesc(val.target.value)} placeholder="Responsible for coordinating squad members "></Input>
                </div>

            </Modal>
        </>
    )
}

export default RoleModal;
