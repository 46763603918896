import React from 'react';
import { Modal, Button, Input, Select, message } from 'antd';
import { APP_URL } from '../../config/axios'
import './style.less'
import { AxiosError } from 'axios';
import { useAuthContext } from '../../context/AuthContext';

type ModalProps = {
    visible: boolean,
    setVisible: Function,
    setSquad: Function
    squad: any
    tribes: any[]
    megaTribes: any[]
}

const SquadModal = ({ visible, setVisible, setSquad, tribes, squad, megaTribes }: ModalProps) => {
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [squadName, setSquadName] = React.useState('');
    const [squadDesc, setSquadDesc] = React.useState('');
    const [filteredTribes, setFilteredTribes] = React.useState(tribes);
    const [megatribeId, setMegaTribeId] = React.useState(null);
    const [tribeId, setTribeId] = React.useState(null);
    const [tribeDisable, setTribeDisable] = React.useState(true);

    const { authKey } = useAuthContext();

    const { Option } = Select

    const postSquad = () => {

        setConfirmLoading(true);

        let squadData = {
            name: squadName,
            description: squadDesc,
            tribeId: tribeId
        }

        if (tribeId === null) {
            message.warning("Please select a Tribe")
        }
        else if (squadName.length < 1) {
            message.warning("Please enter a squad name")
        }
        else {
            APP_URL.post("squad", squadData, {
                headers:
                    { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                setSquad(res.data)
                setVisible(false);
                setConfirmLoading(false);
                message.success("Squad added successfully")
                setSquadName("")
                setSquadDesc("")
            }).catch((err: AxiosError) => {
                message.error("Something went wrong adding the squad")
                console.log(err.message)
            })
        }
    }
    const putSquad = () => {
        setConfirmLoading(true);

        squad.name = squadName
        squad.description = squadDesc
        squad.tribeId = tribeId

        if (tribeId === null) {
            message.warning("Please select a tribe")
        }
        else if (squadName.length < 1) {
            message.warning("Please enter a squad name")
        }
        else {

            APP_URL.put(`squad/${squad.id}`, squad, {
                headers:
                    { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                setSquad(null)
                setVisible(false)
                setConfirmLoading(false);
                message.success("Squad updated successfully")
                setSquadName("")
                setSquadDesc("")
            }).catch((err: AxiosError) => {
                message.error("Something went wrong updating the squad")
                console.log(err.message)
            })
        }
    }

    React.useEffect(() => {
        if (squad !== null) {
            setSquadName(squad.name)
            setSquadDesc(squad.description)
            setTribeId(squad.tribeId)
            setFilteredTribes(tribes)

        }
        else {
            setSquadName("")
            setSquadDesc("")
            setFilteredTribes(tribes)
            setTribeId(null)
        }
    }, [squad])

    const handleOk = () => {

        if (squad === null) {
            postSquad()
        } else {
            putSquad()
        }
    };

    const handleCancel = () => {
        setVisible(false);
        setConfirmLoading(false)
    };

    const handleSelect = (val) => {
        setTribeId(val)
    }
    const handleMegaTribeSelect = (val) => {
        setMegaTribeId(val)
        setFilteredTribes(tribes.filter(t => t.megatribeId === val))
        setTribeId(null)
        setTribeDisable(false)
    }

    return (
        <>
            <Modal
                title="Create a Squad"
                visible={visible}
                className="data-input-modal"
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText={"Tribe added"}
                footer={[
                    <Button key="back" className="cancel-btn" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" className="reg-btn" type="primary" loading={confirmLoading} onClick={handleOk}>
                        Continue
                    </Button>,
                ]}
            >
                <div>
                    <div>

                        {/* Mega Tribe:
                        <Select onChange={handleMegaTribeSelect} value={megatribeId} className='modal-select'>

                            {megaTribes.map((mt, i) => (
                                <Option key={i} value={mt.id}><h6 className="role">{mt.name} </h6> </Option>
                            ))}
                        </Select> */}

                        Tribe:
                        <Select onChange={handleSelect} value={tribeId} className='modal-select'>
                            {filteredTribes.map((t, i) => (
                                <Option key={i} value={t.id}><h6 className="role">{t.name} </h6> </Option>
                            ))}
                        </Select>


                    </div>
                    <div>Squad Name:</div> <Input className='modal-data-input' value={squadName} onChange={(val) => setSquadName(val.target.value)} placeholder="UX Designer"></Input>
                    <div>Description:</div> <Input className='modal-data-input' value={squadDesc} onChange={(val) => setSquadDesc(val.target.value)} placeholder="UX designers team members in the design tribe"></Input>
                </div>
            </Modal>
        </>
    )
}

export default SquadModal;
