import React from 'react';
import { Modal, Button, Input, Select, message } from 'antd';
import { APP_URL } from '../../config/axios'
import './style.less'
import { AxiosError } from 'axios';
import { useAuthContext } from '../../context/AuthContext';

type ModalProps = {
    visible: boolean,
    setVisible: Function,
    setTribe: Function,
    tribe: any,
    megaTribes: any[]
}

const TribeModal = ({ visible, setVisible, setTribe, tribe, megaTribes, }: ModalProps) => {
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [tribeName, setTribeName] = React.useState('');
    const [tribeDesc, setTribeDesc] = React.useState('');
    const [megaTribeId, setMegaTribeId] = React.useState(null);
    const { authKey } = useAuthContext();

    const { Option } = Select

    React.useEffect(() => {
        if (tribe !== null) {
            setTribeName(tribe.name)
            setTribeDesc(tribe.description)
            setMegaTribeId(tribe.megatribeId)

        }
        else {
            setTribeName('')
            setTribeDesc('')
            setMegaTribeId(null)
        }
    }, [tribe])

    const postTribe = () => {
        setConfirmLoading(true);

        let tribeData = {
            name: tribeName,
            description: tribeDesc,
            megaTribeId: megaTribeId
        }
        if (megaTribeId === null) {
            message.warning("Please select a Megatribe")
        }
        else if (tribeName.length < 1) {
            message.warning("Please enter a tribe name")
        }
        else {

            APP_URL.post("tribe", tribeData, {
                headers:
                    { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                setTribe(res.data)
                setVisible(false);
                setConfirmLoading(false);
                message.success("Tribe added successfully")
                setTribeName("")
                setTribeDesc("")
            }).catch((err: AxiosError) => {
                message.error("Something went wrong adding the tribe")
                console.log(err.message)
            })
        }
    }
    const putTribe = () => {
        setConfirmLoading(true);

        tribe.name = tribeName
        tribe.description = tribeDesc
        tribe.megaTribeId = megaTribeId

        if (megaTribeId === null) {
            message.warning("Please select a Megatribe")
        }
        else if (tribeName.length < 1) {
            message.warning("Please enter a tribe name")
        }
        else {

            APP_URL.put(`tribe/${tribe.id}`, tribe, {
                headers:
                    { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                setTribe(null)
                setVisible(false)
                setConfirmLoading(false);
                message.success("Tribe updated successfully")
                setTribeName("")
                setTribeDesc("")
            }).catch((err: AxiosError) => {
                message.error("Something went wrong updating the tribe")
                console.log(err.message)
            })
        }
    }

    const handleOk = () => {
        if (tribe === null) {
            postTribe()
        }
        else {
            putTribe()
        }
    };

    const handleCancel = () => {
        setVisible(false);
        setConfirmLoading(false)
    };

    const handleSelect = (val) => {
        setMegaTribeId(val)
    }

    return (
        <>
            <Modal
                title="Create a Tribe"
                visible={visible}
                className="data-input-modal"
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText={"Tribe added"}
                footer={[
                    <Button key="back" className="cancel-btn" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" className="reg-btn" type="primary" loading={confirmLoading} onClick={handleOk}>
                        Continue
                    </Button>,
                ]}
            >
                <div>
                    <div>
                        <div>Mega tribe:</div>
                        <Select onChange={handleSelect} value={megaTribeId} className='modal-select'>
                            {megaTribes.map((mt, i) => (
                                <Option key={i} value={mt.id}><h6 className="role">{mt.name} </h6> </Option>
                            ))}
                        </Select>
                    </div>
                    <div>Name:</div> <Input value={tribeName} className='modal-data-input' onChange={(val) => setTribeName(val.target.value)} placeholder="Engineering"></Input>
                    <div>Description:</div> <Input value={tribeDesc} className='modal-data-input' onChange={(val) => setTribeDesc(val.target.value)} placeholder="Engineering team members from the room"></Input>
                </div>
            </Modal>
        </>
    )
}

export default TribeModal;
