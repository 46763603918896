import React from 'react';
import { Button, Drawer, Menu } from 'antd';
import { hamburger, logoColored, bellOutlined } from '../../assets';
import { Layouts } from '../../utils/enums';
import { NavLink } from 'react-router-dom';
import './style.less';

const Mobile = ({ routes }) => {
  const [visible, setVisible] = React.useState(false);
  const [activePath, setActivePath] = React.useState('');

  const activeRoute = routeName =>
    window.location.href.indexOf(routeName) > -1
      ? setActivePath(routeName)
      : '';

  React.useEffect(() => {
    routes.map(prop => {
      if (prop.layout !== Layouts.Admin && prop.layout !== Layouts.Employeee && !prop.collapse) return null;

      return activeRoute(prop.path);
    });
  }, [routes]);

  const generateMenuItems = defaultRoutes =>
    defaultRoutes.map(prop => {
      if (prop.layout !== Layouts.Admin && prop.layout !== Layouts.Employeee && !prop.collapse) return null;

      /* if (prop.collapse) {
        return (
          <li
            className={getCollapseInitialState(prop.views) ? 'active' : ''}
            key={prop.name}
          >
            <div className="Main">
              <span className="sidebar-mini-icon">{prop.icon}</span>
              <span className="sidebar-normal">{prop.name}</span>
            </div>
            <ul className="hidden">
              <p className="activeHidden">{prop.name}</p>
              <hr />
              {createLinks(prop.views)}
            </ul>
          </li>
        );
      } */
      return (
        <Menu.Item
          onClick={() => setVisible(!visible)}
          style={styles.icon}
          key={prop.path}
          icon={prop.icon}
        >
          <NavLink
            style={styles.spacing}
            to={prop.layout + prop.path}
            activeClassName="activeLink"
          >
            {prop.name}
          </NavLink>
        </Menu.Item>
      );
    });

  return (
    <nav className="navbar-mob">
      <Button
        className="drawer"
        type="text"
        icon={<img src={hamburger} alt="hamburger-menu" height={15} />}
        onClick={() => setVisible(true)}
      />

      <img src={logoColored} alt="alx-logo-colored" />

      <Button
        className="drawer"
        type="text"
        icon={<img src={bellOutlined} alt="bell-outlined" height={20} />}
        onClick={() => setVisible(true)}
      />

      <Drawer
        zIndex={99999}
        placement="left"
        onClose={() => setVisible(false)}
        visible={visible}
        width="80%"
        closable
      >
        <Menu theme="light" defaultSelectedKeys={[activePath]}>
          {generateMenuItems(routes)}
        </Menu>
      </Drawer>
    </nav>
  );
};

const styles = {
  icon: { display: 'flex', alignItems: 'center' },
  spacing: { marginLeft: 15 }
};

export default Mobile;
