import React from 'react';
import './style.less';
import { Modals, Buttons, Spinners } from '../../../components'
import { APP_URL } from '../../../config/axios'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Select, Input, Rate, Radio, Button, Popconfirm, message } from 'antd';
import { useAuthContext } from '../../../context/AuthContext';
import { useReviewContext } from '../../../context/ReviewsContext';
import { AxiosError } from 'axios';

type SurveyProps = {
    isCampaign: boolean | null,
    campaign : any [] | null
}

export const Survey = ({isCampaign, campaign}: SurveyProps) => {
    const [questions, setQuestions] = React.useState([])
    const [value, setValue] = React.useState(1);
    const [surveys, setSurveys] = React.useState([]);
    const [campaignSurveys, setCampaignSurveys] = React.useState([]);
    const [questionTypes, setQuestionTypes] = React.useState([]);
    const [selectedSurvey, setSelectedSurvey] = React.useState(null);
    const [selectedClosingMsg, setSelectedClosingMsg] = React.useState('');
    const [selectedOpeningMsg, setSelectedOpeningMsg] = React.useState('');
    const [currentQuestion, setCurrentQuetsion] = React.useState(null);
    const [reviewSchedules, setReviewSchedules] = React.useState([]);
    const [surveyModalVisible, setSurveyModalVisible] = React.useState(false)
    const [campaignSurveyModalVisible, setCampaignModalVisible] = React.useState(false);
    const [surveyMessModalVisible, setSurveyMessModalVisible] = React.useState(false)
    const [questionModalVisible, setQuestionModalVisible] = React.useState(false)
    const [selectedCampaign] = React.useState<any>(campaign);
    const [loading, setLoading] = React.useState(false);

    const { authKey } = useAuthContext();
    const { globalOrg } = useReviewContext();
    const { Option } = Select
    const { TextArea } = Input

    React.useEffect(() => {
        console.log(selectedCampaign?.id);
    }, [])

    React.useEffect(() => {
        if (isCampaign===false){
            APP_URL.get(`survey/org/${globalOrg.id}`,
            {
                headers:
                    { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                if (res.status === 200) {
                    setSurveys(res.data)
                }
            })
            .catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            })
        }   
        else{
            setLoading(true);
            APP_URL.get(`survey/campaign/${selectedCampaign.id}`,
            {
                headers:
                    { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                if (res.status === 200) {
                    setCampaignSurveys(res.data)
                    setLoading(false);
                }
            })
            .catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message);
                    setLoading(false);
                }
            })
        }

    }, [globalOrg, authKey, isCampaign, selectedCampaign?.id])

    React.useEffect(() => {
        if (selectedSurvey) {
            setQuestions(selectedSurvey?.questions)
            setSelectedClosingMsg(selectedSurvey?.closingMessage)
            setSelectedOpeningMsg(selectedSurvey?.welcomeMessage)
        }
    }, [selectedSurvey])

    React.useEffect(() => {
        APP_URL.get("reviewschedule",
            {
                headers:
                    { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                if (res.status === 200) {
                    setReviewSchedules(res.data)
                }
            })
            .catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            })
    }, [authKey])

    React.useEffect(() => {
        APP_URL.get("questiontype",
            {
                headers:
                    { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                if (res.status === 200) {
                    setQuestionTypes(res.data)
                }
            })
            .catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            })
    }, [authKey])

    const addNewQuestion = (type_id) => {
            //Insert question
        // console.log(type_id)
            if (selectedSurvey) {
                let qcontent = {}
                if (type_id == 1) {
                    qcontent = { title: "Ask Question here" }
                }
                if (type_id == 2) {
                    qcontent = { title: "Ask Question here" }
                }
                if (type_id == 3) {
                    qcontent = { title: "Ask Question here", items: [] }
                }
                if (type_id == 4) {
                    qcontent = { title: "Ask Question here", items: [] }
                }
                if (type_id == 5) {
                    qcontent = { title: "New Section" }
                }

                let question = {
                    questionTypeId: type_id, questionContent: JSON.stringify(qcontent)
                    , surveyId: selectedSurvey.id, orderIndex: questions.length
                }

                APP_URL.post("question", question,
                {
                    headers:
                        { "Authorization": `Basic ${authKey}` }
                }).then(res => {

                    setQuestions(questions => [...questions, res.data])
                    // console.log(res.data)
                })
                .catch((err: AxiosError) => {
                    if (err.response) {
                        console.log(err.message)
                    }
                })
            }
            else {
                message.warning("Please select a survey")
            }
    }

    const sortQuestion = (res) => {

        const items = Array.from(questions)
        const [sortedItems] = items.splice(res.source.index, 1)
        items.splice(res.destination.index, 0, sortedItems)
        items.map((q, inx) => {
            q.orderIndex = inx;
            APP_URL.put(`question/${q.id}`, q, {
                headers:
                    { "Authorization": `Basic ${authKey}` }
            }).catch((err: AxiosError) => {
                console.log(err.message)
            })

        })
        setQuestions(items)
    }

    const onRadioChange = e => {
        setValue(e.target.value);
    };

    const showQuestionModal = q => {
        setCurrentQuetsion(q)
        setQuestionModalVisible(true)
    }

    const renderElement = (q: { questionTypeId: number; questionContent: string; }) => {

        if (q.questionTypeId == 1) {
            return <TextArea rows={3} />
        }
        else if (q.questionTypeId == 2) {
            return <Rate className='q-rate' />
        }
        else if (q.questionTypeId == 3) {
            return (
                <Radio.Group onChange={onRadioChange} className='multi-choice' value={value}>
                    {JSON.parse(q.questionContent).items?.map((m, inx) => (
                        <Radio key={inx} value={m}>{m}</Radio>
                    ))}
                </Radio.Group>
            )
        }
        else if (q.questionTypeId == 4) {
            return (
                // <Checkbox.Group className='cb-question' options={JSON.parse(q.questionContent).items} />
                <Select mode="tags" size={"large"} style={{ width: '100%', height: "60px" }} placeholder="Select an item or type something new and press enter"   >
                    {JSON.parse(q.questionContent).items?.map((m) => (
                        <Option value={m}>{m}</Option>
                    ))}
                </Select>
            )
        }
        else if (q.questionTypeId == 5) {
            return <span className='q-header'></span>
        }
        else {
            return <button>404</button>
        }
    }

    const confirmDeleteQuestion = (e, q) => {

        APP_URL.delete(`question/${q.id}`,
            {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                setQuestions(questions.filter(item => item.id !== q.id));
                message.success("Question deleted")
            })
            .catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            })
    };

    const handleSelectSurvey = (index) => {
        APP_URL.get(`survey/org/${globalOrg.id}`,
        {
            headers:
                { "Authorization": `Basic ${authKey}` }
        }).then(res => {
            if (res.status === 200) {
                setSurveys(res.data)
                setSelectedSurvey(res.data[index])
            }
        })
        .catch((err: AxiosError) => {
            if (err.response) {
                console.log(err.message)
            }
        })
    }

    const handleCreateSurvey = ()=>{
        if (isCampaign=== true){
            setCampaignModalVisible(true)
        }
        else{ 
            setSurveyModalVisible(true)
        }
    }

    return <main>
        <Spinners.CustomSpinner tip={"Getting surveys"} spinning={loading}> 
            <DragDropContext onDragEnd={sortQuestion}>
                <Droppable droppableId="droppable-1" type="QUESTION">
                    {(provided, snapshot) => (
                        <div id="survey-root" >
                            <div className='question-type-list'>
                                <div className='add-question-text'>
                                    Add question
                                </div>
                                {questionTypes.map((type, i) => (
                                    <div onClick={() => addNewQuestion(type.id)} className='q-type-contain'>
                                        <div className='question-type-box'>
                                            <img alt="text icon" src={process.env.PUBLIC_URL + '/icons/' + type.iconName} />
                                        </div>
                                        <div className='type-name'>{type.name}</div>
                                    </div>
                                ))}
                                {provided.placeholder}
                            </div>

                            <div className='survey-section' >
                                <div className='survey-controls'>
                                    
                                    {isCampaign=== false &&
                                        <Select size={'large'} onChange={handleSelectSurvey} placeholder="Select Survey" className='survey-select'>
                                            {surveys.map((surv, inx) => (
                                                <Option key={inx} value={inx} >{surv.title}</Option>
                                            ))}
                                        </Select>
                                    }

                                    {isCampaign=== true &&
                                        <Select size={'large'} onChange={handleSelectSurvey} placeholder="Select Campaign Survey" className='survey-select'>
                                            {campaignSurveys.map((surv, inx) => (
                                                <Option key={inx} value={inx} >{surv.title}</Option>
                                            ))}
                                        </Select>
                                    }

                                    <div onClick={() => { handleCreateSurvey(); }}>
                                        <Buttons.Primary text="Create New Survey" />
                                    </div>
                                    <Modals.SurveyModal reviewSchedules={reviewSchedules} setSurvey={setSelectedSurvey} visible={surveyModalVisible} setVisible={setSurveyModalVisible} />
                                    <Modals.QuestionModal question={currentQuestion} visible={questionModalVisible} setVisible={setQuestionModalVisible} />
                                    <Modals.SurveyMessageModal visible={surveyMessModalVisible} setSurvey={setSelectedSurvey} setVisible={setSurveyMessModalVisible} survey={selectedSurvey} />
                                    <Modals.CampaignSurveyModal visible={campaignSurveyModalVisible} setSurvey={setSelectedSurvey} setVisible={setCampaignModalVisible} campaignId={selectedCampaign?.id} campaignName={selectedCampaign?.name}/>
                                </div>

                                <div {...provided.droppableProps} ref={provided.innerRef} style={{ backgroundColor: snapshot.isDraggingOver ? '#1D394852' : '#fff' }}
                                    {...provided.droppableProps} className='survey-droppable-area'>
                                    <div key={-200} className='welcome-closing-messsage' >
                                        <span className='opening-msg'>
                                            {selectedOpeningMsg}
                                        </span>
                                        {selectedSurvey &&
                                            <Button onClick={() => setSurveyMessModalVisible(true)} className='edit-q'>Edit</Button>
                                        }
                                    </div>
                                    {questions.map((q, i) => (
                                        <Draggable index={i} key={q.id + "-order"} draggableId={String(q.id) + "-order"} >
                                            {(provider) => (
                                                <div key={q.id + "-key"} {...provider.draggableProps} {...provider.dragHandleProps} ref={provider.innerRef} className='question' >
                                                    <div className='question-top'>
                                                        {q.questionTypeId === 5 ?
                                                            <span className='question-title header'>{String(JSON.parse(q.questionContent).title)}</span>
                                                            :
                                                            <span className='question-title'>{String(JSON.parse(q.questionContent).title)}</span>
                                                        }
                                                        <span className='question-controls'>
                                                            <Button onClick={() => showQuestionModal(q)} className='edit-q'>Edit</Button>
                                                            <Popconfirm
                                                                title="Delete this question?"
                                                                onConfirm={(e) => confirmDeleteQuestion(e, q)}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <Button className='delete-q'>Delete</Button>
                                                            </Popconfirm>
                                                        </span>
                                                    </div>
                                                    <div className="question-element">
                                                        {renderElement(q)}
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    <div key={-201} className='welcome-closing-messsage' >
                                        <span className='opening-msg'>
                                            {selectedClosingMsg}
                                        </span> 
                                        
                                        {selectedSurvey &&
                                            <Button onClick={() => setSurveyMessModalVisible(true)} className='edit-q'>Edit</Button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </Spinners.CustomSpinner>
    </main>;
};