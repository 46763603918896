import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './style.less'

const antIcon = <LoadingOutlined className="spinner-icon" spin />;

type SpinnerProps = {
    spinning: boolean,
    tip: string,
    children: React.ReactNode
}

const CustomSpinner = ({ spinning, tip, children }: SpinnerProps) => {
    return (
        <Spin tip={tip} className={'custom-spin'} spinning={spinning} indicator={antIcon}>
            {children}
        </Spin>
    );
};

export default CustomSpinner