import React from 'react';
import { Modal, Button, Input, message } from 'antd';
import { APP_URL } from '../../config/axios'
import './style.less'
import { AxiosError } from 'axios';
import { useAuthContext } from '../../context/AuthContext';
import { useReviewContext } from '../../context/ReviewsContext';

type ModalProps = {
    visible: boolean,
    setVisible: Function,
    setDepartment: Function,
    department: any
}

const DepartmentModal = ({ visible, setVisible, setDepartment, department }: ModalProps) => {
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [departmentName, setDepartmentName] = React.useState('');
    const [description, setDescription] = React.useState('');
    const { authKey } = useAuthContext();
    const { globalOrg } = useReviewContext();

    const postDepartment = () => {

        setConfirmLoading(true);

        let departmentData = {
            deptName: departmentName,
            description: description,
            orgId: globalOrg.id
        }

        if (departmentName.length < 1) {
            message.warning("department name")
        } else {
            APP_URL.post("department", departmentData, {
                headers:
                    { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                setDepartment(res.data)
                setVisible(false);
                setConfirmLoading(false);
                message.success("department added successfully")
                setDepartmentName("")
                setDescription("")
            }).catch((err: AxiosError) => {
                console.log(err.message)
                message.error("There was an error adding department")
            })
        }
    }
    const putMegaTribe = () => {

        setConfirmLoading(true);
        department.deptName = departmentName
        department.description = description

        if (departmentName.length < 1) {
            message.warning("department name")
        }
        else {
            APP_URL.put(`department/${department.id}`, department, {
                headers:
                    { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                setDepartment(null)
                setVisible(false);
                setConfirmLoading(false);
                message.success("Mega tribe updated successfully")
                setDepartmentName("")
                setDescription("")
            }).catch((err: AxiosError) => {
                console.log(err.message)
                message.error("There was an error updating mega tribe")
            })
        }
    }

    const handleOk = () => {
        if (department === null) {
            postDepartment()
        }
        else {
            putMegaTribe()
        }
    };

    React.useEffect(() => {
        if (department !== null) {
            setDepartmentName(department.deptName)
            setDescription(department.description)

        }
        else {
            setDepartmentName('')
            setDescription('')
        }
    }, [department])

    const handleCancel = () => {
        setVisible(false);
        setConfirmLoading(false)
    };

    return (
        <>
            <Modal
                title="Create new department"
                visible={visible}
                className="data-input-modal"
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText={"department added"}
                footer={[
                    <Button key="back" className="cancel-btn" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" className="reg-btn" type="primary" loading={confirmLoading} onClick={handleOk}>
                        Continue
                    </Button>,
                ]}
            >
                <div>
                    <div>Department Name:</div> <Input className='modal-data-input' value={departmentName} onChange={(val) => setDepartmentName(val.target.value)} placeholder="Finance"></Input>
                    <div>Description:</div> <Input className='modal-data-input' value={description} onChange={(val) => setDescription(val.target.value)} placeholder="The finance department"></Input>
                </div>

            </Modal>
        </>
    )
}

export default DepartmentModal;
