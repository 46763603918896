import React from 'react';
import './style.less';
import { Avatar, Button, Table, Rate, Radio, List, Tabs } from 'antd';
import { Bar } from 'react-chartjs-2';
import { withRouter, useLocation } from 'react-router-dom';
import { logoColored } from '../../assets';


const PrintableReview = () => {
    const [selectedEmployee, setSelectedEmployee] = React.useState(null);
    const [selectedResponse, setSelectedResponse] = React.useState(null);
    const [currentSelfReview, setCurrentSelfReview] = React.useState(null)
    const [yourRatings, setYourRatings] = React.useState(null)
    const [otherRatings, setOtherRatings] = React.useState(null)


    const barData = {
        labels: ['Courage', 'Humility', 'Adventure', 'Initiative', 'Resilience'],

        datasets: [

            {
                label: `${selectedEmployee?.firstname}'s rating`,
                backgroundColor: '#CED8D9',
                borderColor: '#CED8D9',
                borderWidth: 1,
                hoverBackgroundColor: '#E0E9EA',
                hoverBorderColor: '#E0E9EA',
                data: yourRatings
            },
            {
                label: `${selectedResponse?.reviewedInfo.firstname}'s Rating`,
                backgroundColor: '#F9F3EF',
                borderColor: '#F9F3EF',
                borderWidth: 1,
                hoverBackgroundColor: '#FFF7F1',
                hoverBorderColor: '#FFF7F1',
                data: otherRatings
            }
        ]
    };

    const col = [
        {
            title: <div className="user-table-head"></div>,
            dataIndex: 'pic',
            key: 'pic',
            render: (text, record) => <div><Avatar size="default" className='table-avatar' src={text} /></div>
        },

        {
            title: <div className="user-table-head">Your Answer</div>,
            dataIndex: 'yourAnswer',
            // width: 350,
            key: 'yourAnswer',
        },
        {
            title: <div className="user-table-head"></div>,
            dataIndex: 'otherPic',
            key: 'otherPic',
            render: (text, record) => <div><Avatar size="default" className='table-avatar' src={text} /></div>
        },

        {
            title: <div className="user-table-head">{selectedResponse?.reviewedInfo?.firstname || selectedResponse?.reviewedInfo?.firstName} Answers</div>,
            dataIndex: 'otherAnswer',
            // width: 350,
            key: 'otherAnswer',
        }

    ];

    React.useEffect(() => {

        let revData = JSON.parse(localStorage.getItem("reviewData"))
        setSelectedEmployee(revData.selectedEmployee)
        setSelectedResponse(revData.selectedResponse)
        setCurrentSelfReview(revData.currentSelfReview)
        setYourRatings(revData.yourRatings)
        setOtherRatings(revData.otherRatings)

    }, [])

    React.useEffect(() => {
        if (selectedEmployee && selectedResponse) {
            document.title = selectedResponse?.reviewedInfo?.firstname + "'s review of " + selectedEmployee?.firstname
            window.print()
        }
   
    }, [selectedResponse, selectedEmployee])


    const parseString = (questionTitle: string, user?: any) => {
        return questionTitle.replace(/{employee}/g, user.firstname).replace(/{manager}/g, user.firstname)
    }

    const renderFirstBar = () => {
        return (
            <>
                <div className='question-title header-res' > CHAIR values ratings</div>

                <div style={{ width: '45%', margin: '20px 0px' }} >
                    <Bar
                        data={barData}
                        height={350}
                        options={{
                            maintainAspectRatio: false,
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true,
                                        stepSize: 1
                                    }
                                }]
                            }
                        }}
                    />
                </div>
            </>
        )
    }

    const renderHeaders = (index: number) => {
        if (index === 0) {
            return (<div className='question-title header-res' >Skills</div>)
        }
        if (index === 13) {
            return (<div className='question-title header-res' >Overall Performance Rating</div>)
        }
        if (index === 15) {
            return (<div className='question-title header-res' >Start Stop Continue</div>)
        }
    }

    const renderElement = (q: any, inx: number) => {
        if (q.questionTypeId == 2) {
            let response = (q.questionResponse)
            let response2 = selectedResponse?.responseJson[inx].questionResponse
            return (
                <div >
                    <div className='text-flex'>
                        <Avatar style={{ marginRight: '5px' }} src={selectedEmployee?.profilePic} />
                        <span className='text-response'>"{response[0]}"</span>
                    </div>

                    <div className='text-flex'>
                        <Avatar style={{ marginRight: '5px' }} src={selectedResponse?.reviewedInfo.profilePic} />
                        <span className='text-response'>"{response2[0]}"</span>

                    </div>
                </div>

            )
        }
        else if (q.questionTypeId == 3) {
            let response = (q.questionResponse)
            let response2 = selectedResponse?.responseJson[inx].questionResponse

            if (response > response2) {
                return (
                    <div className='list-flex'>
                        <div style={{ borderRight: '1px solid #000' }} className='rate-contain printable'>
                            <Avatar className='rate-vatar' style={{ marginRight: '5px' }} src={selectedEmployee?.profilePic} />
                            <Rate disabled value={response[0]} className='green-rating' />
                        </div>
                        <div className='rate-contain printable'>
                            <Avatar className='rate-vatar' style={{ marginRight: '5px' }} src={selectedResponse?.reviewedInfo.profilePic} />
                            <Rate disabled value={response2[0]} className='red-rating' />
                        </div>
                    </div>
                )
            }
            else if (response < response2) {
                return (

                    <div className='list-flex'>
                        <div style={{ borderRight: '1px solid #000' }} className='rate-contain printable'>
                            <Avatar className='rate-vatar' style={{ marginRight: '5px' }} src={selectedEmployee?.profilePic} />
                            <Rate disabled value={response[0]} className='q-rate red-rating' />
                        </div>
                        <div className='rate-contain printable'>
                            <Avatar className='rate-vatar' style={{ marginRight: '5px' }} src={selectedResponse?.reviewedInfo.profilePic} />
                            <Rate disabled value={response2[0]} className='q-rate green-rating' />
                        </div>
                    </div>

                )
            }
            else {
                return (

                    <div className='list-flex'>
                        <div className='rate-contain printable' style={{ borderRight: '1px solid #000' }} >
                            <Avatar className='rate-vatar' style={{ marginRight: '5px' }} src={selectedEmployee?.profilePic} />

                            <Rate disabled value={response[0]} className='q-rate green-rating' />
                        </div>
                        <div className='rate-contain printable'>
                            <Avatar className='rate-vatar' style={{ marginRight: '5px' }} src={selectedResponse?.reviewedInfo.profilePic} />

                            <Rate disabled value={response2[0]} className='q-rate green-rating' />
                        </div>
                    </div>
                )
            }
        }

        else if (q.questionTypeId == 4) {
            let response = (q.questionResponse)
            return (
                <Radio.Group defaultValue={response[0]} className='multi-choice'>
                    {response.map((m) => (
                        <Radio value={m}>{m}</Radio>
                    ))}
                </Radio.Group>
            )

        }
        else if (q.questionTypeId == 5) {
            let response = (q.questionResponse)
            let response2 = selectedResponse?.responseJson[inx].questionResponse
            return (
                <div className='list-flex'>
                    <div style={{ width: '45%' }}>
                        <List dataSource={response}

                            renderItem={item => (
                                <List.Item>
                                    <Avatar style={{ marginRight: '5px' }} src={selectedEmployee?.profilePic} /> {item}
                                </List.Item>
                            )}
                        />
                    </div>
                    <div style={{ width: '45%' }}>
                        <List dataSource={response2}
                            renderItem={item => (
                                <List.Item className='list-item'>
                                    <Avatar style={{ marginRight: '5px' }} src={selectedResponse?.reviewedInfo.profilePic} /> {item}
                                </List.Item>
                            )}
                        />
                    </div>

                </div>
            )
        }
        else if (q.questionTypeId == 7) {
            return <span className='q-header'></span>
        }

        else {
            return <button>404</button>
        }
    }

    const renderDeliverableList = () => {

        let yourDeliverables = [
            { yourAnswer: currentSelfReview?.responseJson[2].questionResponse, pic: selectedEmployee?.profilePic, otherAnswer: selectedResponse?.responseJson[2].questionResponse, otherPic: selectedResponse?.reviewedInfo.profilePic },
            { yourAnswer: currentSelfReview?.responseJson[4].questionResponse, pic: selectedEmployee?.profilePic, otherAnswer: selectedResponse?.responseJson[4].questionResponse, otherPic: selectedResponse?.reviewedInfo.profilePic },
            { yourAnswer: currentSelfReview?.responseJson[6].questionResponse, pic: selectedEmployee?.profilePic, otherAnswer: selectedResponse?.responseJson[6].questionResponse, otherPic: selectedResponse?.reviewedInfo.profilePic },
        ]

        return (
            <div style={{ marginTop: '15px' }} >
                <div className='question-title header-res' > 3.0) Key deliverables</div>
                <div className='compare-table'>
                    <Table columns={col} dataSource={yourDeliverables}
                    />

                </div>
            </div>
        )
    }

    return (
        <>
            {selectedEmployee && currentSelfReview && barData && selectedResponse &&
                <div className='printable-root'>
                    <div className='print-header'>
                        <img className="logo" style={{ marginLeft: '15px' }} src={logoColored} alt="logo-colored" />
                    </div>
                    <div className='compare-review-print'>
                        <p className='info'>
                            {selectedEmployee.firstname}'s self review compared against {selectedResponse?.reviewedInfo?.firstname || selectedResponse?.reviewedInfo?.firstName}'s review
                        </p>
                        {renderFirstBar()}
                        {currentSelfReview.responseJson.map((q, i) => ((i < 8 || i > 12) && (i !== 2 && i !== 4 && i !== 6) &&
                            <>
                                {renderHeaders(i)}
                                <div key={i} className='question' >
                                    <div className='question-top'>
                                        <div className='question-title' style={{ fontSize: '1.4rem', fontWeight: 'bold' }} > {parseString(q.questionTitle, selectedEmployee)}</div>
                                        <span className='question-controls'></span>
                                    </div>
                                    <div className="question-element">
                                        {renderElement(q, i)}
                                    </div>
                                </div>
                                {i === 1 &&
                                    renderDeliverableList()
                                }
                            </>
                        ))}
                    </div>
                </div>
            }
        </>
    )
}

export default withRouter(PrintableReview);