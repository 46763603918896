import React from 'react';
import { Modal, Button, Input, message, Form, Select } from 'antd';
import { APP_URL } from '../../config/axios'
import './style.less'
import { useAuthContext } from '../../context/AuthContext';
import { FormInstance } from 'antd/lib/form';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from "uuid";
import { AiOutlineCamera } from 'react-icons/ai'
import UploadClient from '@uploadcare/upload-client'

type ModalProps = {
    visible: boolean,
    setVisible: Function,
    setIsRegisterModalVisible: Function,
    userData: any,
}

const CreateOrganisationModal = ({ userData, visible, setVisible, setIsRegisterModalVisible }: ModalProps) => {
    const [formValues, setFormValues] = React.useState<any>();
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [orgSize, setOrgSize] = React.useState("");
    const [logo, setLogo] = React.useState<any>(null);
    const { authenticateUser, currentUser, authKey } = useAuthContext();
    const formRef = React.useRef<FormInstance>();
    const history = useHistory();
    const client = new UploadClient({ publicKey: 'cac56bd94bebce5eeb68' })

    const validateMessages = {
        required: "'${name}' is required!",
    };

    const handleCancel = () => {
        setVisible(false);
        setIsRegisterModalVisible(true);
        setConfirmLoading(false)
    };

    const handleOk = () => {
        formRef.current.submit();
    };

    const onSubmit = () => {
        createUserAuth()
    }

    const createUserAuth = () => {
        setConfirmLoading(true);

        const _userData = Object.assign({}, userData, { userType: 2 })
        APP_URL.post("userauth", _userData)
            .then(res => {
                authenticateUser(btoa(`${userData.email}:${userData.authSystemId}`), res.data);
            })
            .catch((err) => {
                console.log(err.message)
                setConfirmLoading(false);
                message.error('There was an error creating your account');
            })
    }

    const generateUID = () => {
        // I generate the UID from two parts here 
        // to ensure the random number provide enough bits.
        var firstPart = (Math.random() * 46656) | 0;
        var secondPart = (Math.random() * 46656) | 0;
        var firstPartStr = ("000" + firstPart.toString(36)).slice(-3);
        var secondPartStr = ("000" + secondPart.toString(36)).slice(-3);
        return firstPartStr + secondPartStr;
    }

    const createOrganisation = (userId) => {
        setConfirmLoading(true);
        client
            .uploadFile(logo, { publicKey: "cac56bd94bebce5eeb68" })
            .then(file => {
            const _formValues = {
                name: formValues?.name,
                logo: file.cdnUrl,
                description: formValues?.description,
                organizationCode: `ORG-${generateUID().toUpperCase()}-${userId}`,
                paymentPlan: 'paymentPlan',
                size: orgSize,
                authenticationType: userData.authType

            }
            APP_URL.post('organization', _formValues, {
                headers: { "Authorization": `Basic ${authKey}` }
            })
                .then((res) => {
                    createEmployee({ org: res.data })
                }).catch(err => {
                    console.log(err.message);
                    setConfirmLoading(false);
                    message.error('There was an error creating your organisation');
                })

                // console.log(file.uuid)
            })

            }

    const createEmployee = ({ org }) => {
        const _employee = {
            "email": currentUser.email,
            "userAuthId": currentUser.id,
            "firstname": currentUser.firstName,
            "lastname": currentUser.lastName,
            "roleId": null,
            "profilePic": currentUser.profilePic,
            "active": true,
            "orgId": org.id,
            "managerId": null,
            "deptId": null,
            "userTypeId": 2
        }

        APP_URL.post("employee", _employee, {
            headers: { "Authorization": `Basic ${authKey}` }
        })
            .then((res) => {
                console.log(res.data);
                message.success(`Organisation '${org.name}' was created`);
                history.push('/admin/review-schedule')
            })
            .catch((err) => {
                console.log(err.message);
                setConfirmLoading(false);
                message.error('There was an error creating your organisation"');
            })
    }

    const handleSize = (val) => {
        setOrgSize(val)
    }

    React.useEffect(() => {
        if (currentUser && authKey && confirmLoading) {
            createOrganisation(currentUser.id);
        }
    }, [currentUser, authKey]);

    React.useEffect(() => {
        console.log(logo)
    }, [logo])

    return (
        <>
            <Modal
                destroyOnClose={true}
                width={"40%"}
                title="Create Organisation"
                visible={visible}
                className="register-modal data-input-modal"
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText={"Organisation created"}
                footer={[
                    <Button key="back" className="cancel-btn" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" className="reg-btn" type="primary" loading={confirmLoading} onClick={handleOk}>
                        Continue
                    </Button>,
                ]}
            >
                <Form
                    ref={formRef}
                    name="basic"
                    validateMessages={validateMessages}
                    initialValues={{ remember: false }}
                    onFinish={onSubmit}
                    onValuesChange={(prevValues, values) => { setFormValues(values) }}
                //   onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        className="form-item"
                        name="name"
                        rules={[{ required: true, }]}
                    >
                        <Input placeholder={'Organisation\'s name'} className='modal-data-input'></Input>
                    </Form.Item>

                    <Form.Item
                        className="form-item"
                        name="description"
                        rules={[{ required: true, }]}
                    >
                        <Input.TextArea placeholder={'Description'} className='modal-data-input' />
                    </Form.Item>

                    <Form.Item
                        className="form-item"
                        name="frequency"
                        rules={[{ required: true, }]}
                    >
                        <Select onChange={handleSize} style={{ width: '100%' }} placeholder="Organization Size"   >
                            {["1-50", "51-500", "501-5000", "5000+"].map((size) => (
                                <Select.Option value={size}>{size}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        className="form-item"
                        name="logo"
                    // rules={[{ required: true, }]}
                    >
                        <Input type='file' name="file" id="file" onChange={(e) => setLogo(e.target.files[0])} className=' inputfile' ></Input>
                        <label className='profile-avatar' htmlFor="file"><AiOutlineCamera className='profilepic-edit' /></label>
                        <div className='logo-label'>{logo === null ? "Upload Logo" : logo.name}</div>

                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default CreateOrganisationModal;
