import { Button } from 'antd';
import React from 'react';

import './style.less';

type Prop = {
    text: string
}

const Primary = ({ text }: Prop) => {
    return (
        <Button className="primary">{text}</Button>
    );
};

export default Primary;
