import React from 'react';
import { Buttons  } from '../../../components'
import { Button, Table, Popconfirm, message, Tooltip, Input } from 'antd'
import { useAuthContext } from '../../../context/AuthContext';
import './style.less';
import Avatar from 'antd/lib/avatar/avatar';
import { APP_URL } from '../../../config/axios'
import { AxiosError } from 'axios';
import { Modals } from '../../../components'
import { Drawers } from '../../../components'
import { MdDelete } from 'react-icons/md'
import { AiOutlineSearch, AiOutlineCheck } from 'react-icons/ai';
import { useReviewContext } from '../../../context/ReviewsContext';

export const UserManagement = () => {
    const userMgmtItems = ["Departments", "Employees", "Roles",]
    const { authKey, currentUser } = useAuthContext();
    const { globalOrg } = useReviewContext();
    const [clickedItemInx, setClickedItemInx] = React.useState(0)
    const [isSearching, setIsSearching] = React.useState(false)
    const [popConfirmVisible, setPopConfirmVisible] = React.useState(false)
    const [popConfirmLoading, setPopConfirmLoading] = React.useState(false)
    const [userType, setUserType] = React.useState({})
    const [employee, setEmployee] = React.useState({})
    const [department, setDepartment] = React.useState({})
    const [role, setRole] = React.useState({})
    const [employeesDisplay, setEmployeesDisplay] = React.useState([])
    const [employeesResultsDisplay, setEmployeesResultsDisplay] = React.useState([])
    const [rolesDisplay, setRolesDisplay] = React.useState([])
    const [deptDisplay, setDeptDisplay] = React.useState([])
    const [userTypeVisible, setUserTypeVisible] = React.useState(false)
    const [employeeVisible, setEmployeeVisible] = React.useState(false)
    const [excelVisible, setExcelVisible] = React.useState(false)
    const [departmentVisible, setDepartmentVisible] = React.useState(false)
    const [roleVisible, setRoleVisible] = React.useState(false)
    const [empDrawerVisible, setEmpDrawerVisible] = React.useState(false)
    const [clickedEmployee, setClickedEmployee] = React.useState({})
    const [searchQuery, setSearchQuery] = React.useState('')

    const getRolesByOrg = () => {
        APP_URL.get(`role/org/${globalOrg.id}`,
        {
            headers:
                { "Authorization": `Basic ${authKey}` }
        }).then(res => {
            if (res.status === 200) {
                setRolesDisplay(res.data)
            }
        })
        .catch((err: AxiosError) => {
            if (err.response) {
                console.log(err.message)
            }
        })
    }

    const getDeptsOrg = () => {
        APP_URL.get(`department/org/${globalOrg.id}`,
        {
            headers:
                { "Authorization": `Basic ${authKey}` }
        }).then(res => {
            if (res.status === 200) {
                setDeptDisplay(res.data)
            }
        })
        .catch((err: AxiosError) => {
            if (err.response) {
                console.log(err.message)
            }
        })
    }

    const getEmployeesByOrg = () => {
        APP_URL.get(`employee/org/${globalOrg.id}`,
        {
            headers:
                { "Authorization": `Basic ${authKey}` }
        }).then(res => {
            if (res.status === 200) {
                setEmployeesDisplay(res.data)
            }
        })
        .catch((err: AxiosError) => {
            if (err.response) {
                console.log(err.message)
            }
        })
    }

    React.useEffect(() => {
        if (globalOrg) {
            getEmployeesByOrg()
        }
    }, [employee])

    React.useEffect(() => {
        if (globalOrg) {
            getEmployeesByOrg()
        }
    }, [excelVisible])

    React.useEffect(() => {
        if (globalOrg && authKey) {
            getEmployeesByOrg()
            getRolesByOrg()
            getDeptsOrg()
        }
    }, [])

    React.useEffect(() => {
        if (globalOrg) {
            getRolesByOrg()
        }
    }, [role])

    React.useEffect(() => {
        if (globalOrg) {
            getDeptsOrg()
        }
    }, [department])

    const handleSearch = (e) => {
        setSearchQuery(e.target.value)
    }

    const getDisplayPic = (emp) => {
        if (emp.profilePic !== null) { return String(emp.profilePic); }
        else { return `https://ui-avatars.com/api/?name=${emp.firstname + ' ' + emp.lastname}&background=59394A&color=fff&size=256` }
    }

    React.useEffect(() => {
        if (String(searchQuery).length > 0) {
            setIsSearching(true)
            // Filter item in user files, mimicking the "LIKE %%" in an sql query
            setEmployeesResultsDisplay(
                employeesDisplay.filter(item => (item.firstname.toLowerCase().indexOf(searchQuery) >= 0
                    || item.lastname.toLowerCase().indexOf(searchQuery) >= 0) && globalOrg && globalOrg?.id)
            );
        } else {
            setIsSearching(false)
        }
    }, [searchQuery])

    const right: FixedType = 'right';

    const getUserTypeString = (x: number) => {
        if (x === 9) {
            return 'Employee'
        } else if (x === 1) {
            return 'Admin'
        }
    }

    const alxRolesColumns = [
        {
            title: <div className="user-table-head">Name</div>,
            dataIndex: 'roleName',
            key: 'roleName',
        },
        {
            title: <div className="user-table-head">Description</div>,
            dataIndex: 'description',
            key: 'description',
            width: 400,
            ellipsis: true,
            render: desc => (
                <Tooltip placement="topLeft" title={desc}>
                    {desc}
                </Tooltip>
            ),
        },
        {
            title: <div className="user-table-head">Update</div>,
            dataIndex: 'id',
            key: 'id',
            fixed: right,
            render: (test, row) =>
                <div>
                    <Button className="update-btn" onClick={() => { setRoleVisible(true); setRole(row); }} >Update</Button>
                    <Popconfirm
                        title="Delete role?"
                        onConfirm={() => handleDeleteItemOk(row.id, "role")}
                        onCancel={handlePopCancel}
                    >
                        <Button className="delete-btn" icon={<MdDelete />} ></Button>
                    </Popconfirm>
                </div>
        },
    ]

    const deptColumns = [
        {
            title: <div className="user-table-head">Name</div>,
            dataIndex: 'deptName',
            key: 'deptName',
        },
        {
            title: <div className="user-table-head">Description</div>,
            dataIndex: 'description',
            key: 'description',
            width: 400,
            ellipsis: true,
            render: desc => (
                <Tooltip placement="topLeft" title={desc}>
                    {desc}
                </Tooltip>
            ),
        },

        {
            title: <div className="user-table-head">Update</div>,
            dataIndex: 'id',
            key: 'id',
            fixed: right,
            render: (test, row) =>
                <div>
                    <Button className="update-btn" onClick={() => { setDepartmentVisible(true); setDepartment(row); }}>Update</Button>
                    <Popconfirm
                        title="Delete department?"
                        onConfirm={() => handleDeleteItemOk(row.id, "department")}
                        onCancel={handlePopCancel}
                    >
                        <Button className="delete-btn" icon={<MdDelete />} ></Button>
                    </Popconfirm>
                </div>
        },
    ]

    const activeUsersColumns = [
        {
            title: <div className="user-table-head">Name</div>,
            dataIndex: 'firstname',
            key: 'firstname',
            width: 250,
            ellipsis: true,
            sorter: {
                compare: (a, b) => a.firstname.localeCompare(b.firstname),
                multiple: 1
            },
            render: (text, record) => <div><Avatar size="small" className='table-avatar' src={getDisplayPic(record)} /> {text} {record.lastname}</div>
        },
        {
            title: <div className="user-table-head">Email</div>,
            dataIndex: 'email',
            width: 300,
            key: 'email',
        },
        {
            title: <div className="user-table-head">Role</div>,
            dataIndex: 'roleId',
            width: 200,
            key: 'roleId',
            render: (text) =>
                <span>
                    {String(rolesDisplay.length > 0 && rolesDisplay.filter(r => r.id === text)[0]?.roleName)}
                </span>
        },

        {
            title: <div className="user-table-head">Is Active</div>,
            dataIndex: 'active',
            width: 150,
            // fixed: right,
            key: 'active',
            render: (active) =>
                <>
                    {
                        Boolean(active) === true ?
                            <AiOutlineCheck className='check-icon' />
                            :
                            <Button className="update-btn">Invite</Button>
                    }
                </>
        },
        {
            title: <div className="user-table-head">Actions</div>,
            dataIndex: 'id',
            // fixed: right,
            key: 'id',
            render: (test, row) =>
                <div>
                    <Button className="update-btn" onClick={(e) => { e.stopPropagation(); setEmployeeVisible(true); setEmployee(row); }} >Update</Button>
                    <Popconfirm
                        title="Delete employee"
                        onConfirm={(e) => { e.stopPropagation(); handleDeleteItemOk(row.id, "employee") }}
                        onCancel={handlePopCancel}
                    >
                        <Button onClick={(e) => e.stopPropagation()} className="delete-btn" icon={<MdDelete />} ></Button>
                    </Popconfirm>
                </div>
        },
    ];


    const handleDeleteItemOk = (id, controller: string) => {
        setPopConfirmLoading(true);
        console.log(id)

        const hide = message.loading(`Deleting ${controller}`, 0)
        APP_URL.delete(`${controller}/${id}`, {
            headers:
                { "Authorization": `Basic ${authKey}` }
        }).then((r) => {

            if (controller === "employee") {
                setEmployee(r.data)
                setPopConfirmVisible(false);
                hide()
            }

            else if (controller === "usertype") {
                setUserType(r.data)
                setPopConfirmVisible(false);
                hide()
            }

            else if (controller === "role") {
                setRole(r.data)
                setPopConfirmVisible(false);
                hide()
            }

        }).catch((err: AxiosError) => {
            if (err.response) {
                message.error("there was a problem deleting item")
                console.log(err.message)
            }
        })
    };

    const handlePopCancel = (e) => {
        e.stopPropagation()
        // console.log('Clicked cancel button');
        setPopConfirmVisible(false);
    };

    const handleItemChange = (inx) => {
        setSearchQuery('');
        setClickedItemInx(inx)
    }



    return (
        <main id="user-mgmt-root">
            <div className='left-nav'>
                <ul className='nav-list' >
                    {userMgmtItems.map((item, inx) => (
                        <li key={inx} onClick={() => handleItemChange(inx)} className={`nav-list-item ${clickedItemInx === inx ? "active-item" : ""}`}>{item}</li>
                    ))}

                </ul>
            </div>
            <div className="right">

                {clickedItemInx === 0 &&
                    <div>
                    <div onClick={() => { setDepartmentVisible(true); setDepartment(null) }}>
                        <Buttons.Primary text="ADD NEW DEPARTMENT" />
                        </div>
                        <div className='user-mgmt-container'>
                            {/* <Inputs.Search placeholder="Search team members" /> */}

                        <Table className='user-table' dataSource={deptDisplay} rowKey="id" columns={deptColumns} />
                        </div>
                    </div>
                }
                {clickedItemInx === 1 &&
                    <div>
                        <div className='contain-buttons'>
                            <div  onClick={() => { setEmployeeVisible(true); setEmployee(null); }}>
                                <Buttons.Primary text="ADD NEW EMPLOYEE" />
                            </div>
                            <div onClick={() => { setExcelVisible(true); setEmployee(null); }}>
                                <Buttons.Primary text="ADD FROM EXCEL FILE" />
                            </div>
                        </div>
                    
                        <div className='user-mgmt-container'>
                        <Input className='search' value={searchQuery} onChange={(e) => handleSearch(e)} placeholder={"Search by name"} size={"large"} prefix={<AiOutlineSearch />} />
                            {isSearching ?
                            <Table scroll={{ x: 1050 }} className='user-table' onRow={(record, rowIndex) => {
                                    return {
                                        onClick: event => { setEmpDrawerVisible(true); setClickedEmployee(record) }, // click row
                                    };
                                }} dataSource={employeesResultsDisplay} rowKey="id" columns={activeUsersColumns} />
                                :
                            <Table scroll={{ x: 1050 }} className='user-table' onRow={(record, rowIndex) => {
                                    return {
                                        onClick: event => { setEmpDrawerVisible(true); setClickedEmployee(record) }, // click row
                                    };
                                }} dataSource={employeesDisplay} rowKey="id" columns={activeUsersColumns} />
                            }
                        </div>
                    </div>
                }
                {clickedItemInx === 2 &&
                    <div>
                        <div onClick={() => { setRoleVisible(true); setRole(null); }}>
                            <Buttons.Primary text="ADD NEW ROLE" />
                        </div>
                        <div className='user-mgmt-container'>
                            {/* <Inputs.Search placeholder="Search team members" /> */}

                            <Table className='user-table' dataSource={rolesDisplay} rowKey="id" columns={alxRolesColumns} />
                        </div>
                    </div>
                }
            </div>
            <Drawers.EmployeeReviewDrawer visible={empDrawerVisible} setVisible={setEmpDrawerVisible} employee={clickedEmployee} />
            <Modals.UserTypeModal visible={userTypeVisible} setUserType={setUserType} userType={userType} setVisible={setUserTypeVisible} />
            <Modals.EmployeeModal visible={employeeVisible} roles={rolesDisplay} setEmployee={setEmployee} employee={employee} employees={employeesDisplay} departments={deptDisplay} setVisible={setEmployeeVisible} />
            <Modals.ExcelUploadModal visible={excelVisible} setVisible={setExcelVisible} />
            <Modals.DepartmentModal visible={departmentVisible} setDepartment={setDepartment} department={department} setVisible={setDepartmentVisible} />
            <Modals.RoleModal visible={roleVisible} role={role} setRole={setRole} setVisible={setRoleVisible} />
        </main>
    )
};

export declare type FixedType = 'left' | 'center' | 'right';
