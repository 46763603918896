import React from 'react';
import { Landing } from './landing';
import { Layouts } from '../utils/enums';
import { Home } from './employee-pages/home';

export const pages = [
  {
    path: '/',
    exact: true,
    component: props => <Landing {...props} />,
    layout: Layouts.Landing
  },
  {
    path: '/join/:orgCode',
    exact: true,
    component: props => <Landing {...props} />,
    layout: Layouts.Landing
  },
];
