import React from 'react';

import { Layouts } from '../../utils/enums';
import { Dropdown, Layout, Menu, Select, Tag, Tooltip } from 'antd';
import { logoColored, organisationsLogo } from '../../assets';
import { RiLogoutCircleLine } from 'react-icons/ri'
import { AiOutlineMenuUnfold, AiOutlineMenuFold, AiOutlineSetting, AiOutlineUsergroupAdd, AiOutlineDown } from 'react-icons/ai'
import './style.less';
import { NavLink } from 'react-router-dom';
import { useReviewContext } from '../../context/ReviewsContext';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../context/AuthContext';

type DrawerProps = {
  collapsed: boolean
  routes: any,
  layout?: Layouts
}

const Main = ({ routes, collapsed, layout }: DrawerProps) => {
  const { Sider } = Layout;

  const [activePath, setActivePath] = React.useState('');
  const { setIsLogged, setAuthKey, setCurrentUser } = useAuthContext();
  const { setGlobalPendingReview, globalUserEmp, setGlobalUserEmp, globalOrg } = useReviewContext();

  const activeRoute = routeName =>
    window.location.href.indexOf(routeName) > -1
      ? setActivePath(routeName)
      : '';

  React.useEffect(() => {
    routes.map(prop => {
      if (prop.layout !== Layouts.Admin && prop.layout !== Layouts.Employeee && !prop.collapse) return null;

      return activeRoute(prop.path);
    });
  }, [routes]);

  const generateMenuItems = defaultRoutes =>
    defaultRoutes.map(prop => {
      if (prop.layout !== Layouts.Admin && prop.layout !== Layouts.Employeee && !prop.collapse) return null;

      return (
        <Menu.Item style={collapsed ? (styles.icon) : (styles.iconFlex)} key={prop.path} icon={prop.icon}>
          <NavLink
            style={styles.spacing}
            to={prop.layout + prop.path}
            activeClassName="activeLink"
          >
            {prop.name}
          </NavLink>
        </Menu.Item>
      );
    });


  const logout = () => {
    localStorage.setItem('authKey', null)
    setAuthKey(null)
    setIsLogged(false)
    setCurrentUser(null)
    setGlobalPendingReview([])
    setGlobalUserEmp(null)
  }
  const styles = {
    iconFlex: { display: 'flex', alignItems: 'center' },
    icon: { alignItems: 'center' },
    spacing: { marginLeft: 26, marginBottom: 5 }
  };

  const menuItemsAdmin = [
    { title: 'Organisation Settings', icon: <AiOutlineSetting size={16} />, },
    { title: 'Invite Users', icon: <AiOutlineUsergroupAdd size={16} />, }
  ]

  const menu = (
    <Menu mode="inline">
      {menuItemsAdmin.map(item => (
        <Menu.Item
          className='dropdown-sidemenu-item'
          style={collapsed && { justifyContent: 'center', }}
          icon={item.icon}>
          {!collapsed && <div className='dropdown-sidemenu-text'>{item.title}</div>}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Sider trigger={null} collapsible collapsed={collapsed} width={260} collapsedWidth={80} className="custom-sider">
      <div className="logo-container">
        <img className="logo" src={logoColored} alt="logo-colored" />
      </div>

      {/* <Dropdown overlay={menu} > */}
        <div className='dropdown-sidemenu'>
          {!collapsed &&
            <div >
            <img src={globalOrg?.logo} alt="organisations logo" className='dropdown-sidemenu-logo' />
              <span style={{ marginLeft: '16px' }}>{globalOrg?.name}</span>
            </div>
          }
          <AiOutlineDown size={16} />
        </div>
      {/* </Dropdown> */}

      {layout === Layouts.Employeee && globalUserEmp && !globalUserEmp?.active &&
        <div className='dropdown-sidemenu'>
          <Tooltip title="HR Manager needs to approve account.">
            <Tag className='employee-active-tag' style={{ marginRight: 0 }} >
              <InfoCircleOutlined />
              {!collapsed &&
                <span className='employee-active-tag-text'>Pending Approval</span>
              }
            </Tag>
          </Tooltip>
        </div>
      }

      <Menu theme="light" mode={'inline'} defaultSelectedKeys={[activePath]}>
        {generateMenuItems(routes)}
        <Menu.Item style={collapsed ? (styles.icon) : (styles.iconFlex)} className='bottom-item' icon={<RiLogoutCircleLine className='menu-icon' />} >
          <NavLink
            style={styles.spacing}
            to={'/'}
            activeClassName="activeLink"
            onClick={logout}
          >
            Log out
          </NavLink>
        </Menu.Item>
      </Menu>
    </Sider>
  );

};

export default Main;