import React from 'react';
import './style.less';
import { useAuthContext } from '../../context/AuthContext';
import { APP_URL } from '../../config/axios'
import { AxiosError } from 'axios';
import { Avatar, Button, Table, Input, Select, message, Rate, Radio, List, Tabs } from 'antd';
import { AiOutlineSearch } from 'react-icons/ai';
import ReactToPdf from "react-to-pdf";
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import ReactQuill from 'react-quill';
import { Link, Redirect } from 'react-router-dom';

const StaffReviews = ({ history }) => {
    // const [employees, setEmployees] = React.useState([]);
    const { authKey, currentUser } = useAuthContext();
    const [employees, setEmployees] = React.useState([]);
    const [selectedEmployee, setSelectedEmployee] = React.useState(null);
    const [surveyResponses, setSurveyResponses] = React.useState([])
    const [surveys, setSurveys] = React.useState([]);
    const [displaySurveyResponses, setDisplaySurveyResponses] = React.useState([])
    const [selectedResponse, setSelectedResponse] = React.useState(null);
    const [currentSelfReview, setCurrentSelfReview] = React.useState(null)
    const [yourRatings, setYourRatings] = React.useState([])
    const [otherRatings, setOtherRatings] = React.useState([])
    const [showReview, setShowReview] = React.useState(false)
    const [yourReviewOfOther, setYourReviewOfOther] = React.useState(null)
    // const [reviewSummaryComment, setReviewSummaryComment] = React.useState(null);
    const [inverse, setInverse] = React.useState(false)
    const [searchQuery, setSearchQuery] = React.useState('')
    const [isSearching, setIsSearching] = React.useState(false)
    const [filteredEmployees, setFilteredEmployees] = React.useState([])
    const [employeesResults, setEmployeesResults] = React.useState([])
    const [reviewSchedule, setReviewSchedule] = React.useState([]);
    const [selectedReviewSchedule, setSelectedReviewSchedule] = React.useState(0);
    const [selectedEmployeeInx, setSelectedEmployeeInx] = React.useState(-1);

    const col = [
        {
            title: <div className="user-table-head"></div>,
            dataIndex: 'pic',
            key: 'pic',
            render: (text, record) => <div><Avatar size="default" className='table-avatar' src={text} /></div>
        },

        {
            title: <div className="user-table-head">Your Answer</div>,
            dataIndex: 'yourAnswer',
            // width: 350,
            key: 'yourAnswer',
        },
        {
            title: <div className="user-table-head"></div>,
            dataIndex: 'otherPic',
            key: 'otherPic',
            render: (text, record) => <div><Avatar size="default" className='table-avatar' src={text} /></div>
        },

        {
            title: <div className="user-table-head">{selectedResponse?.reviewedInfo?.firstname || selectedResponse?.reviewedInfo?.firstName} Answers</div>,
            dataIndex: 'otherAnswer',
            // width: 350,
            key: 'otherAnswer',
        }

    ];

    const barData = {
        labels: ['Courage', 'Humility', 'Adventure', 'Initiative', 'Resilience'],

        datasets: [

            {
                label: `${selectedEmployee?.firstname}'s rating`,
                backgroundColor: '#CED8D9',
                borderColor: '#CED8D9',
                borderWidth: 1,
                hoverBackgroundColor: '#E0E9EA',
                hoverBorderColor: '#E0E9EA',
                data: yourRatings
            },
            {
                label: `${selectedResponse?.reviewedInfo.firstname}'s Rating`,
                backgroundColor: '#F9F3EF',
                borderColor: '#F9F3EF',
                borderWidth: 1,
                hoverBackgroundColor: '#FFF7F1',
                hoverBorderColor: '#FFF7F1',
                data: otherRatings
            }
        ]
    };

    const barData2 = {
        labels: ['Courage', 'Humility', 'Adventure', 'Initiative', 'Resilience'],

        datasets: [

            {
                label: `${yourReviewOfOther?.reviewedInfo?.firstname}'s Rating`,
                backgroundColor: '#CED8D9',
                borderColor: '#CED8D9',
                borderWidth: 1,
                hoverBackgroundColor: '#E0E9EA',
                hoverBorderColor: '#E0E9EA',
                data: yourRatings
            },
            {
                label: `Your Rating`,
                backgroundColor: '#F9F3EF',
                borderColor: '#F9F3EF',
                borderWidth: 1,
                hoverBackgroundColor: '#FFF7F1',
                hoverBorderColor: '#FFF7F1',
                data: otherRatings
            }
        ]
    };

    const activeUsersColumns = [
        {
            title: <div className="user-table-head">Name</div>,
            dataIndex: 'firstname',
            // width: 250,
            key: 'firstname',
            // ellipsis: true,
            sorter: {
                compare: (a, b) => a.firstname.localeCompare(b.firstname),
                multiple: 1
            },
            render: (text, record) => <div><Avatar size="small" className='table-avatar' src={getDisplayPic(record)} /> {text} {record.lastname}</div>
        },
    ];


    const parseString = (questionTitle: string, user?: any) => {
        return questionTitle.replace(/{employee}/g, user.firstname).replace(/{manager}/g, user.firstname)
    }

    const getDisplayPic = (emp) => {
        if (emp.profilePic !== null) { return String(emp.profilePic); }
        else { return `https://ui-avatars.com/api/?name=${emp.firstname + ' ' + emp.lastname}&background=59394A&color=fff&size=256` }
    }

    const handleSelectReviewSchedule = (index) => {
        setSelectedReviewSchedule(index)
    }


    const getActiveReviewSchdedules = () => {
        APP_URL.get(`reviewschedule`,
            {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                setReviewSchedule(res.data);
            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            })
    }

    const getEmployees = () => {
        APP_URL.get(`employee`,
            {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then(user => {
                setEmployees(user.data)
                setEmployeesResults(user.data)

            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            })

    }

    const getSurveyResponses = () => {
        APP_URL.get(`surveyresponse`,
            {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then(surveys => {
                setSurveyResponses(surveys.data);
            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            })
    }


    const getSurveys = () => {
        APP_URL.get(`survey`,
            {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then(surveys => {
                setSurveys(surveys.data);
            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            })
    }

    const getSelfReview = (survRes) => {
        let selectedRseponseJson

        if ((Array.isArray(survRes.responseJson))) {
            selectedRseponseJson = survRes.responseJson
        } else {
            selectedRseponseJson = JSON.parse(survRes.responseJson)
        }
        selectedRseponseJson = selectedRseponseJson.sort((a, b) => (a.order) - (b.order));
        survRes.responseJson = selectedRseponseJson
        // console.log(survRes.responseJson, survRes)
        setSelectedResponse(survRes)
        setShowReview(true)
        // let currSelfReview: any = surveyResponses.filter(x => x.reviewScheduleId === reviewSchedule[selectedReviewSchedule].id && x.reviewerId === selectedEmployee.id && x.reviewedId === selectedEmployee.id)[0]

        // if (currSelfReview) {
        //     let selfReviewRseponseJson
        //     if ((Array.isArray(currSelfReview.responseJson))) {
        //         selfReviewRseponseJson = currSelfReview.responseJson
        //     } else {
        //         selfReviewRseponseJson = JSON.parse(currSelfReview.responseJson)
        //     }
        //     selfReviewRseponseJson = selfReviewRseponseJson.sort((a, b) => (a.order) - (b.order));
        //     currSelfReview.responseJson = selfReviewRseponseJson
        //     // console.log(currSelfReview.responseJson)
        //     setCurrentSelfReview(currSelfReview)

        //     // reset ratings
        //     setYourRatings([])
        //     setOtherRatings([]);

        //     for (let index = 8; index <= 12; index++) {
        //         setYourRatings(arr =>
        //             [...arr, currSelfReview.responseJson[index].questionResponse[0]]
        //         )
        //         setOtherRatings(arr =>
        //             [...arr, survRes.responseJson[index].questionResponse[0]]
        //         )
        //     }

        //     setInverse(false);
        //     setShowReview(true)
        // } else {
        //     message.error("please complete your self review to view results")
        // }
    }

    React.useEffect(() => {
        getSurveyResponses()
        // getQuestionResponses()
        getEmployees()
        getSurveys()
        getActiveReviewSchdedules()
    }, [authKey]);

    React.useEffect(() => {
        if (employees.length > 0 && surveys.length > 0 && surveyResponses.length > 0 && selectedEmployee) {

            // add reviwer, survey and role info
            let _recentlyReviedSurveys = surveyResponses.map((survey) => {
                // survey['reviewedInfo'] = employees.find((x) => survey.reviewerId == x.id);
                survey['reviewedInfo'] = employees.find((x) => survey.reviewerId == x.id);
                survey['surveyInfo'] = surveys.find((x) => survey.surveyId == x.id);
                return survey;
            });

            // remove self reviews
            setDisplaySurveyResponses(_recentlyReviedSurveys.filter(x => x.reviewedId === selectedEmployee.id && x.reviewerId !== x.reviewedId));
        }

    }, [employees, surveyResponses, selectedEmployee, surveys])




    const renderFirstBar = () => {
        return (
            <>
                <div className='question-title header-res' > CHAIR values ratings</div>

                <div style={{ width: '90%', margin: '20px 0px' }} >
                    <Bar
                        data={inverse ? barData2 : barData}
                        height={350}
                        options={{
                            maintainAspectRatio: false,
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true,
                                        stepSize: 1
                                    }
                                }]
                            }
                        }}
                    />
                </div>
            </>
        )
    }

    const renderHeaders = (index: number) => {
        if (index === 0) {
            return (<div className='question-title header-res' >Skills</div>)
        }
        if (index === 13) {
            return (<div className='question-title header-res' >Overall Performance Rating</div>)
        }
        if (index === 15) {
            return (<div className='question-title header-res' >Start Stop Continue</div>)
        }
    }

    const renderElement = (q: any, inx: number) => {
        if (q.questionTypeId == 2) {
            let response = (q.questionResponse)
            let response2 = selectedResponse?.responseJson[inx].questionResponse
            return (
                <div >
                    <div className='text-flex'>
                        <Avatar style={{ marginRight: '5px' }} src={selectedEmployee?.profilePic} />
                        <span className='text-response'>"{response[0]}"</span>
                    </div>

                    <div className='text-flex'>
                        <Avatar style={{ marginRight: '5px' }} src={selectedResponse?.reviewedInfo.profilePic} />
                        <span className='text-response'>"{response2[0]}"</span>

                    </div>
                </div>

            )
        }
        else if (q.questionTypeId == 3) {
            let response = (q.questionResponse)
            let response2 = selectedResponse?.responseJson[inx].questionResponse

            if (response > response2) {
                return (
                    <div className='list-flex'>
                        <div style={{ borderRight: '1px solid #000' }} className='rate-contain'>
                            <Avatar className='rate-vatar' style={{ marginRight: '5px' }} src={selectedEmployee?.profilePic} />
                            <Rate disabled value={response[0]} className='green-rating' />
                        </div>
                        <div className='rate-contain'>
                            <Avatar className='rate-vatar' style={{ marginRight: '5px' }} src={selectedResponse?.reviewedInfo.profilePic} />
                            <Rate disabled value={response2[0]} className='red-rating' />
                        </div>
                    </div>
                )
            }
            else if (response < response2) {
                return (

                    <div className='list-flex'>
                        <div style={{ borderRight: '1px solid #000' }} className='rate-contain'>
                            <Avatar className='rate-vatar' style={{ marginRight: '5px' }} src={selectedEmployee?.profilePic} />
                            <Rate disabled value={response[0]} className='q-rate red-rating' />
                        </div>
                        <div className='rate-contain'>
                            <Avatar className='rate-vatar' style={{ marginRight: '5px' }} src={selectedResponse?.reviewedInfo.profilePic} />
                            <Rate disabled value={response2[0]} className='q-rate green-rating' />
                        </div>
                    </div>

                )
            }
            else {
                return (

                    <div className='list-flex'>
                        <div className='rate-contain' style={{ borderRight: '1px solid #000' }} >
                            <Avatar className='rate-vatar' style={{ marginRight: '5px' }} src={selectedEmployee?.profilePic} />

                            <Rate disabled value={response[0]} className='q-rate green-rating' />
                        </div>
                        <div className='rate-contain'>
                            <Avatar className='rate-vatar' style={{ marginRight: '5px' }} src={selectedResponse?.reviewedInfo.profilePic} />

                            <Rate disabled value={response2[0]} className='q-rate green-rating' />
                        </div>
                    </div>
                )
            }
        }

        else if (q.questionTypeId == 4) {
            let response = (q.questionResponse)
            return (
                <Radio.Group defaultValue={response[0]} className='multi-choice'>
                    {response.map((m) => (
                        <Radio value={m}>{m}</Radio>
                    ))}
                </Radio.Group>
            )

        }
        else if (q.questionTypeId == 5) {
            let response = (q.questionResponse)
            let response2 = selectedResponse?.responseJson[inx].questionResponse
            return (
                <div className='list-flex'>
                    <div style={{ width: '45%' }}>
                        <List dataSource={response}

                            renderItem={item => (
                                <List.Item>
                                    <Avatar style={{ marginRight: '5px' }} src={selectedEmployee?.profilePic} /> {item}
                                </List.Item>
                            )}
                        />
                    </div>
                    <div style={{ width: '45%' }}>
                        <List dataSource={response2}
                            renderItem={item => (
                                <List.Item className='list-item'>
                                    <Avatar style={{ marginRight: '5px' }} src={selectedResponse?.reviewedInfo.profilePic} /> {item}
                                </List.Item>
                            )}
                        />
                    </div>

                </div>
            )
        }
        else if (q.questionTypeId == 7) {
            return <span className='q-header'></span>
        }

        else {
            return <button>404</button>
        }
    }

    const renderDeliverableList = () => {

        let yourDeliverables = [
            { yourAnswer: currentSelfReview?.responseJson[2].questionResponse, pic: selectedEmployee?.profilePic, otherAnswer: selectedResponse?.responseJson[2].questionResponse, otherPic: selectedResponse?.reviewedInfo.profilePic },
            { yourAnswer: currentSelfReview?.responseJson[4].questionResponse, pic: selectedEmployee?.profilePic, otherAnswer: selectedResponse?.responseJson[4].questionResponse, otherPic: selectedResponse?.reviewedInfo.profilePic },
            { yourAnswer: currentSelfReview?.responseJson[6].questionResponse, pic: selectedEmployee?.profilePic, otherAnswer: selectedResponse?.responseJson[6].questionResponse, otherPic: selectedResponse?.reviewedInfo.profilePic },
        ]

        return (
            <div style={{ marginTop: '15px' }} >
                <div className='question-title header-res' > 3.0) Key deliverables</div>
                <div className='compare-table'>
                    <Table columns={col} dataSource={yourDeliverables}
                    />

                </div>
            </div>
        )
    }

    const handleSearch = (e) => {
        setSearchQuery(e.target.value)
    }

    React.useEffect(() => {
        if (String(searchQuery).length > 0) {
            setIsSearching(true)
            const emp = filteredEmployees.length > 0 ? filteredEmployees : employees;

            // Filter item in user files, mimicking the "LIKE %%" in an sql query
            setEmployeesResults(
                emp.filter(item => item.firstname.toLowerCase().indexOf(searchQuery) >= 0
                    || item.lastname.toLowerCase().indexOf(searchQuery) >= 0)
            );
        } else {
            setIsSearching(false)
        }
    }, [searchQuery])


    const openPrintable = () => {
        let reviewData = { yourRatings: yourRatings, otherRatings: otherRatings, selectedEmployee: selectedEmployee, selectedResponse: selectedResponse, currentSelfReview: currentSelfReview }
        let reviewDataStr = JSON.stringify(reviewData)
        localStorage.setItem('reviewData', reviewDataStr)

        const win = window.open("/viewprintablereview", "_blank");
        win.focus();
    }

    return (
        <>
            <div className='reports-list'>

            </div>
            <div className='user-mgmt-container'>

                <div className='staff-review-con '>

                    <div className='emp-details-con '>
                        <div className='rev-timeline'>
                            {displaySurveyResponses.map((response, inx) => (
                                <div key={inx} className='report-card'>
                                    <div className='circle'>
                                        <Avatar src={response.reviewedInfo && response.reviewedInfo.profilePic} style={{ width: '70px', height: '70px' }} />
                                    </div>
                                    <div style={{ minWidth: '250px' }}>
                                        <div className='between-texts'>
                                            <span className='timeline-text'>{response && moment(response.createAt).format('DD MMMM YYYY')}</span>
                                            <span className='timeline-text'>{response && moment(response.createAt).format('HH:mm A')}</span>
                                        </div>
                                        <hr className='between-line' />
                                        <div className='between-texts'>
                                            <span className='timeline-text'>{response.reviewedInfo && response.reviewedInfo.firstname + ' '
                                                + response.reviewedInfo.lastname}</span>
                                            <div className='view-btns-col'>
                                                <Button className='timeline-btn' onClick={() => getSelfReview(response)} >View review</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {selectedEmployee && displaySurveyResponses.length === 0 &&
                            <p className='review-not-found'> No reviews found for {selectedEmployee?.firstname}</p>
                        }
                        {showReview && 
                            <Tabs defaultActiveKey="1" className='chart-report-tabs '>
                                <Tabs.TabPane tab="Comparison View" key="1" >

                                    <div className='flex-response'>
                                        <div className='compare-review'>
                                            <p className='info'>
                                                {selectedEmployee.firstname}'s self review compared against {selectedResponse?.reviewedInfo?.firstname || selectedResponse?.reviewedInfo?.firstName}'s review
                                            </p>
                                            {/* {renderFirstBar()} */}
                                            {/* {currentSelfReview.responseJson.map((q, i) => ((i < 8 || i > 12) && (i !== 2 && i !== 4 && i !== 6) &&
                                                <>
                                                    {renderHeaders(i)}
                                                    <div key={i} className='question' >
                                                        <div className='question-top'>
                                                            <div className='question-title' style={{ fontSize: '1.4rem', fontWeight: 'bold' }} > {parseString(q.questionTitle, selectedEmployee)}</div>
                                                            <span className='question-controls'></span>
                                                        </div>
                                                        <div className="question-element">
                                                            {renderElement(q, i)}
                                                        </div>
                                                    </div>
                                                    {i === 1 &&
                                                        renderDeliverableList()
                                                    }
                                                </>
                                            ))} */}
                                        </div>
                                    {/* <Link target="_blank" to={{ pathname: '/viewprintablereview', state: }> View printable version </Link> */}
                                    <Button className='ant-btn primary' onClick={openPrintable}>View printable version</Button>
                                    </div>

                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Review Summary" key="2" className='review-summary-admin-tab '>

                                    {selectedResponse?.reviewComments[0] ?
                                    <ReactToPdf>
                                        {({ toPdf, targetRef }) => (
                                            <div ref={targetRef}>
                                                <Button className='ant-btn primary' style={{ float: 'right', marginBottom: '15px' }} onClick={toPdf} ref={targetRef}>Download Pdf</Button>

                                                    <div className='review-instructions'>1) {selectedEmployee.firstname}'s provided summary of the review conversation (include points of alignment and points of misalignment)</div>
                                                    <ReactQuill
                                                        readOnly={true}
                                                        value={selectedResponse?.reviewComments[0]?.comment || ''}
                                                        style={{ height: '30vh' }}
                                                    />
                                                    <div className='review-instructions' style={{ marginTop: '70px' }}>2) Final rating {selectedEmployee.firstname} and the team member have agreed on. Included with a summary on why this rating is an accurate reflection of their performance</div>
                                                    <div className='final-rating'>
                                                        <Rate className='q-rate' disabled value={selectedResponse?.reviewComments[0]?.rating} />
                                                    </div>

                                            </div>
                                        )}

                                    </ReactToPdf>
                                        :
                                        <p className='review-not-found'> No review summary found for {selectedEmployee?.firstname}</p>
                                    }

                                </Tabs.TabPane>
                            </Tabs>
                        }

                    </div>

                    <div className='emp-review-con '>
                        <Select style={{ marginTop: '10px', marginBottom: '10px' }} size={'large'} onChange={handleSelectReviewSchedule} defaultValue={0} className='survey-select survey-select-staff-reviews'>
                            {reviewSchedule.map((rs, inx) => (
                                <Select.Option key={inx} value={inx} >{rs.title}</Select.Option>
                            ))}
                        </Select>
                        <Input className='search' value={searchQuery} onChange={(e) => handleSearch(e)} placeholder={"Search by name"} size={"large"} prefix={<AiOutlineSearch />} />
                        <Table
                            dataSource={employeesResults}
                            columns={activeUsersColumns}
                            pagination={{
                                style: { overflowX: 'scroll', justifyContent: 'flex-start', paddingBottom: '10px' }
                            }}
                            rowClassName={(record, index) => `row-cursor ${index === selectedEmployeeInx ? `table-row-selected ` : ``}`}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => { setSelectedEmployeeInx(rowIndex); setShowReview(false); setSelectedEmployee(record); },
                                };
                            }}
                        />
                    </div>
                </div>



            </div>
        </>
    );
};

export default StaffReviews;
export declare type FixedType = 'left' | 'center' | 'right';
