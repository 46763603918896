import React from 'react';
import { Modal, Button, Input, message } from 'antd';
import { APP_URL } from '../../config/axios'
import './style.less'
import { AxiosError } from 'axios';
import { useAuthContext } from '../../context/AuthContext';

type ModalProps = {
    visible: boolean,
    setVisible: Function,
    survey: any
    setSurvey: any
}

const SurveyMessageModal = ({ visible, setVisible, survey, setSurvey }: ModalProps) => {
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [welomeMessage, setWelcomeMessage] = React.useState('');
    const [closingMessage, setCLosingMessage] = React.useState('');
    const { authKey } = useAuthContext();
    const { TextArea } = Input;

    const putSurvey = () => {
        setConfirmLoading(true);

        let newSurv = {}

        survey.welcomeMessage = welomeMessage
        survey.closingMessage = closingMessage

        newSurv = { ...survey };

        if (closingMessage.length < 1) {
            message.warning("Please select a closing message")
        }
        else if (welomeMessage.length < 1) {
            message.warning("Please enter a welcome message")
        }
        else {
            APP_URL.put(`survey/${survey.id}`, survey, {
                headers:
                    { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                setVisible(false);
                setConfirmLoading(false);
                setSurvey(newSurv)
                message.success("Survey updated successfully")

            }).catch((err: AxiosError) => {
                message.error("Something went wrong updating the survey")
                console.log(err.message)
            })
        }
    }

    React.useEffect(() => {
        if (survey) {
            setWelcomeMessage(survey.welcomeMessage)
            setCLosingMessage(survey.closingMessage)
        }
    }, [survey])

    const handleOk = () => {
        putSurvey()
    };

    const handleCancel = () => {
        setVisible(false);
        setConfirmLoading(false)
    };

    return (
        <>
            <Modal
                title="Create a new Survey"
                visible={visible}
                className="data-input-modal"
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText={"Survey added"}
                footer={[
                    <Button key="back" className="cancel-btn" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" className="reg-btn" type="primary" loading={confirmLoading} onClick={handleOk}>
                        Continue
                    </Button>,
                ]}
            >
                <div>
                    <div>Welcome Message:</div> <TextArea value={welomeMessage} className='modal-data-input' onChange={(val) => setWelcomeMessage(val.target.value)} ></TextArea>
                    <div>Closing Message:</div> <TextArea value={closingMessage} className='modal-data-input' onChange={(val) => setCLosingMessage(val.target.value)} ></TextArea>
                </div>
            </Modal>
        </>
    )
}

export default SurveyMessageModal;
