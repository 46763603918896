import React from 'react';
import { useAuthContext } from '../../context/AuthContext';
import { useReviewContext } from '../../context/ReviewsContext';
import { APP_URL } from '../../config/axios';
import { AxiosError } from 'axios';
import { Avatar, Input, Select, Collapse } from 'antd';
import { AiOutlineSearch } from 'react-icons/ai';

const CampaignReport =()=>{
    const { authKey } = useAuthContext();
    const [employees, setEmployees] = React.useState([]);
    const [searchQuery, setSearchQuery] = React.useState('');
    const [isSearching, setIsSearching] = React.useState(false);
    const [survey, setSurvey] = React.useState([]);
    const [employeeResults, setEmployeeResults] = React.useState<any>([]);
    const { globalUserEmp } = useReviewContext();
    const { globalOrg } = useReviewContext();

    const getDisplayPic = (emp) => {
        if (emp?.profilePic !== null) { return String(emp?.profilePic); }
        else { return `https://ui-avatars.com/api/?name=${emp.firstname + ' ' + emp.lastname}&background=59394A&color=fff&size=256` }
    }

    const getEmployees = () => {
        APP_URL.get(`employee/org/${globalUserEmp.orgId}`,
            {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then(user => {
                setEmployees(user.data)
                setEmployeeResults(user.data)
            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            })
    }

    React.useEffect(() => {
        if (employees.length <1){
            return(
                getEmployees()
            )
        }
    }, [searchQuery])

    const handleSearch = (e) => {
        setSearchQuery(e.target.value)
    }

    React.useEffect(() => {
        if (String(searchQuery).length > 0) {
            setIsSearching(true)
            setEmployeeResults(
                employees.filter(item => item.firstname.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0
                    || item.lastname.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0 
                    ));
        } else {
            setIsSearching(false)
        }
    }, [searchQuery])

    const header = (manager) => {
        return (
            <div><Avatar size="small" className='table-avatar' src={getDisplayPic(manager)} /> {manager.firstname} {manager.lastname}</div>
        );
    }

    return (
        <>

            <Input className='search' value={searchQuery} onChange={(e) => handleSearch(e)} placeholder={"Search by name"} size={"large"} prefix={<AiOutlineSearch />} />

            <Collapse accordion style={{ marginBottom: '16px' }}>
                <Collapse.Panel
                    disabled
                    className='title-collpase-header '
                    style={{ cursor: 'auto !important' }}
                    key={'header'}
                    header='Employee'
                    showArrow={false}
                />
                {(isSearching ? employeeResults : employees).map((mn) => {
                    return (
                        <Collapse.Panel
                            key={mn.id}
                            header={header(mn)}
                        >
                            <div>
                                Responses here
                            </div>
                        </Collapse.Panel>
                    );
                })}
            </Collapse>
        </>
    );
}

export default CampaignReport;