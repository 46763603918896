import React from 'react';
import { Modal, Button, Input, message, Form } from 'antd';
import { APP_URL } from '../../config/axios'
import './style.less'
import { useAuthContext } from '../../context/AuthContext';
import { FormInstance } from 'antd/lib/form';
import { useHistory } from 'react-router-dom';

type ModalProps = {
    visible: boolean,
    setVisible: Function,
    orgCode?: any,
    setIsRegisterModalVisible: Function,
    userData: any,
}

const JoinOrganisationModal = ({ visible, setVisible, userData, orgCode, setIsRegisterModalVisible }: ModalProps) => {
    const [formValues, setFormValues] = React.useState<any>(null);
    const [organizations, setOrganizations] = React.useState<any>([]);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const { authenticateUser, currentUser, authKey } = useAuthContext();
    const formRef = React.useRef<FormInstance>();
    const history = useHistory();

    const validateMessages = {
        required: "'${name}' is required!",
    };

    const handleCancel = () => {
        setVisible(false);
        setIsRegisterModalVisible(true);
        setConfirmLoading(false)
    };

    const handleOk = () => {
        formRef.current.submit();
    };

    const onSubmit = () => {
        const _organisation = organizations.find(x => x.organizationCode === formValues?.code);
        if (_organisation) {
            createUserAuth()
        }
        else {
            message.error(`Could not join organization with code.`)
        }
    }

    const getOrganisations = () => {
        APP_URL.get("organization")
            .then((res) => {
                setOrganizations(res.data);
            })
            .catch((err) => {
                console.log(err.message);
            })
    }

    const createUserAuth = () => {
        setConfirmLoading(true);

        APP_URL.post("userauth", userData)
            .then(res => {
                authenticateUser(btoa(`${userData.email}:${userData.authSystemId}`), res.data);
            })
            .catch((err) => {
                console.log(err.message)
                setConfirmLoading(false);
                message.error('There was an error joining your organisation');
            })
    }

    const createEmployee = () => {
        const _employee = {
            "email": currentUser.email,
            "userAuthId": currentUser.id,
            "firstname": currentUser.firstName,
            "lastname": currentUser.lastName,
            "roleId": null,
            "profilePic": currentUser.profilePic,
            "active": true,
            "orgId": organizations.find(x => x.organizationCode === formValues?.code)?.id,
            "managerId": null,
            "deptId": null,
            "userTypeId": 1
        }
        console.log(_employee, authKey)

        APP_URL.post("employee", _employee, {
            headers: { "Authorization": `Basic ${authKey}` }
        })
            .then((res) => {
                // console.log(res.data);
                message.success(`You have now joined ${organizations.find(x => x.organizationCode === formValues?.code)?.name}`);
                setConfirmLoading(false);
                history.push('/employee/home')
            })
            .catch((err) => {
                console.log(err.message);
                setConfirmLoading(false);
                message.error('There was an error joining the organisation"');
            })
    }

    React.useEffect(() => {
        if (currentUser && authKey && confirmLoading) {
            createEmployee();
        }
    }, [currentUser, authKey])

    React.useEffect(() => {
        getOrganisations()
    }, [])

    return (
        <>
            <Modal
                width={"40%"}
                title="Join Organisation"
                visible={visible}
                className="register-modal data-input-modal"
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText={"Organisation created"}
                footer={[
                    <Button key="back" className="cancel-btn" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" className="reg-btn" type="primary" loading={confirmLoading} onClick={handleOk}>
                        Join
                    </Button>,
                ]}
            >
                <Form
                    ref={formRef}
                    name="basic"
                    validateMessages={validateMessages}
                    initialValues={{ remember: false }}
                    onFinish={onSubmit}
                    onValuesChange={(prevValues, values) => setFormValues(values)}
                >
                    <Form.Item
                        name="code"
                        rules={[{ required: true, }]}
                    >
                        <Input
                            defaultValue={orgCode ?? ''}
                            placeholder={'Organization code'}
                            className='modal-data-input'></Input>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default JoinOrganisationModal;
