import React from 'react';
import './style.less';
import { useAuthContext } from '../../context/AuthContext';
import { useReviewContext } from '../../context/ReviewsContext';
import { APP_URL } from '../../config/axios';
import { AxiosError } from 'axios';
import { Avatar, Input, Select, Collapse } from 'antd';
import { AiOutlineSearch } from 'react-icons/ai';

const GrowsAndGlows =()=>{
    const { authKey } = useAuthContext();
    const [employees, setEmployees] = React.useState([]);
    const [searchQuery, setSearchQuery] = React.useState('');
    const [isSearching, setIsSearching] = React.useState(false);
    const [survey, setSurvey] = React.useState([]);
    const [employeeResults, setEmployeeResults] = React.useState<any>([]);
    const { globalUserEmp } = useReviewContext();
    const { globalOrg } = useReviewContext();

    const getDisplayPic = (emp) => {
        if (emp.profilePic !== null) { return String(emp.profilePic); }
        else { return `https://ui-avatars.com/api/?name=${emp.firstname + ' ' + emp.lastname}&background=59394A&color=fff&size=256` }
    }

    const handleSelectedSurvey = (index) => {
        setEmployees([]);
        setEmployeeResults([]);
        getEmployees(survey[index].id);
    }

    const getReviewSurveys = () => {
        // APP_URL.get(`survey`,
        APP_URL.get(`survey/org/${globalOrg.id}`,
            {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                getEmployees(res.data[0]?.id);
                setSurvey(res.data);
            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            })
    }

    const getEmployees = (surveyId) => {
        APP_URL.get(`employee/org/${globalUserEmp.orgId}`,
        // APP_URL.get(`employee`,
            {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then(user => {
;                user.data.map(user => (
                    APP_URL.get(`SurveyResponse/GetSurveyResponseByManId/${user.id}`,
                    {
                        headers: { "Authorization": `Basic ${authKey}` }
                    }).then(res => {

                        if (res.data.surveyId === surveyId){
                            let growsAndGlows = JSON.parse(res.data.responseJson);

                            let grows = growsAndGlows.filter(item=> item.isGrow === true);

                            let glows = growsAndGlows.filter(item=> item.isGlow === true);

                            user.Grows = grows[0];
                            user.Glows = glows[0];
                            setEmployees(tempList => [...tempList, user])
                        }

                    }).catch((err2: AxiosError) => {
                        setEmployees(tempList => [...tempList, user])
                        if (err2.response) {
                            console.log(err2.message)
                        }
                    })
                ))
            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            })
    }

    React.useEffect(() => {
        getReviewSurveys()
    }, [authKey]);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value)
    }

    React.useEffect(() => {
        if (String(searchQuery).length > 0) {
            setIsSearching(true)
            setEmployeeResults(
                employees.filter(item => item.firstname.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0
                    || item.lastname.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0 
                    || item.Grows?.questionResponse?.filter( it => (it.toLowerCase().indexOf(searchQuery.toLowerCase())>=0 )).length>0
                    || item.Glows?.questionResponse?.filter( it => (it.toLowerCase().indexOf(searchQuery.toLowerCase())>=0 )).length>0
                    ));
        } else {
            setIsSearching(false)
        }
    }, [searchQuery])

    const header = (manager) => {
        return (
            <div><Avatar size="small" className='table-avatar' src={getDisplayPic(manager)} /> {manager.firstname} {manager.lastname}</div>
        );
    }

    return (
        <>

            <Select style={{ marginTop: '10px', marginBottom: '10px' }} size={'large'} onChange={e=>{handleSelectedSurvey(e)}} defaultValue={0} className='survey-select survey-select-staff-reviews'>
                {survey.map((rs, inx) => (
                    <Select.Option key={inx} value={inx} >{rs.title}</Select.Option>
                ))}
            </Select>

            <Input className='search' value={searchQuery} onChange={(e) => handleSearch(e)} placeholder={"Search by name, grows or glows"} size={"large"} prefix={<AiOutlineSearch />} />

            <Collapse accordion style={{ marginBottom: '16px' }}>
                <Collapse.Panel
                    disabled
                    className='title-collpase-header '
                    style={{ cursor: 'auto !important' }}
                    key={'header'}
                    header='Employee'
                    showArrow={false}
                />
                {(isSearching ? employeeResults : employees).map((mn) => {
                    return (
                        <Collapse.Panel
                            key={mn.id}
                            header={header(mn)}
                        >
                            <div>
                                <div className='con-glows'>Consistent Glows:</div>
                                <div className='tags-flex'>
                                    {mn.Glows?.questionResponse.map(x => <div className='tags'>{x}</div>)}
                                </div>

                                <div>
                                <div className='con-glows'>Consistent Grows:</div>
                                <div className='tags-flex'>
                                    {mn.Grows?.questionResponse.map(x => <div className='tags red'>{x}</div>)}
                                </div>
                            </div>
                            </div>
                        </Collapse.Panel>
                    );
                })}
            </Collapse>
        </>
    );
}

export default GrowsAndGlows;