import React from 'react';
import { Modal, Button, message, Select } from 'antd';
import { APP_URL } from '../../config/axios'
import './style.less'
import { AxiosError } from 'axios';
import { useAuthContext } from '../../context/AuthContext';

type ModalProps = {
    visible: boolean,
    setVisible: Function,
    setUserAuth: Function,
    userAuth: any,
    userAuthDisplay: any,
    setUserAuthDisplay: Function
}

const UserAuthModal = ({ visible, setVisible, setUserAuth, userAuth, userAuthDisplay, setUserAuthDisplay }: ModalProps) => {
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [selectedUserType, setSelectedUserType] = React.useState(-1);
    const { authKey } = useAuthContext();

    React.useEffect(() => {
        setSelectedUserType(+userAuth.userType);
    }, [userAuth]);

    const putUserAuth = () => {
        setConfirmLoading(true);
        const _newUserAuth = Object.assign({}, userAuth, { userType: selectedUserType });

        APP_URL.put(`userauth/${userAuth.id}`, _newUserAuth, {
            headers:
            {
                "Authorization": `Basic ${authKey}`
            }
        }).then(res => {
            setVisible(false);
            setConfirmLoading(false);
            message.success("Usertype updated successfully")
            setUserAuth(userAuthDisplay.map(x => {
                if (x.id === _newUserAuth.id) {
                    return _newUserAuth;
                }
                return x;
            }));
        }).catch((err: AxiosError) => {
            setConfirmLoading(false);
            message.error("Something went wrong updating the usertype")
        });
    }

    const handleOk = () => {
        putUserAuth();
    };

    const handleCancel = () => {
        setVisible(false);
        setConfirmLoading(false)
    };

    return (
        <>
            <Modal
                title="Change user type"
                visible={visible}
                className="data-input-modal"
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText={"Usertype added"}
                footer={[
                    <Button key="back" className="cancel-btn" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" className="reg-btn" type="primary" loading={confirmLoading} onClick={handleOk}>
                        Continue
                    </Button>,
                ]}
            >
                <div>
                    {/* <div>User Auth:</div> */}
                    <Select value={selectedUserType} style={{ width: '100%' }} onChange={(_) => setSelectedUserType(_)}>
                        <Select.Option value={1}>Admin</Select.Option>
                        <Select.Option value={9}>Employee</Select.Option>
                    </Select>
                </div>
            </Modal>
        </>
    )
}

export default UserAuthModal;
