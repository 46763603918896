import React from 'react';
import { Drawer } from 'antd';
import {Buttons, Campaigns} from '../../components';
import { Survey} from '../../pages/admin-pages/survey/index';

type DrawerProps = {
    visible: boolean
    setVisible: Function
    campaign: any [] | null
}

const CampaignsDrawer = ({ visible, setVisible, campaign }: DrawerProps) => {
    const campaignItems = ["Responses By Employee", "All Responses", "Survey"]
    const [clickedItemInx, setClickedItemInx] = React.useState(0);

    const onClose = () => {
        setVisible(false);
        setClickedItemInx(0);
    }

    const handleItemChange = (inx) => {
        setClickedItemInx(inx)
    }

    const exportToExcel = () => {

    }

    return (
        <>
            <Drawer
                placement="left"
                closable={true}
                onClose={onClose}
                visible={visible}
                width={'100%'}
                className='employee-drawer'
                style={{ position: 'absolute' }}
                getContainer={() => document.getElementById("campaigns")}
            >

                <main id="user-mgmt-root">
                    <div className='left-nav'>
                        <ul className='nav-list' >
                            {campaignItems.map((item, inx) => (
                                <li key={inx} onClick={() => handleItemChange(inx)} className={`nav-list-item ${clickedItemInx === inx ? "active-item" : ""}`}>{item}</li>
                            ))}
                        </ul>
                    </div>

                    <div className="right">
                        {clickedItemInx ===0 &&
                            <main>
                                <div onClick={() => exportToExcel()}>
                                    <Buttons.Primary text="Export to Excel"/>
                                </div>

                                <Campaigns.CampaignReport/>
                            </main>
                        }

                        {clickedItemInx ===1 &&
                            <main>
                                <div onClick={() => exportToExcel()}>
                                    <Buttons.Primary text="Export to Excel"/>
                                </div>
                                <div>
                                    View All Reports
                                </div>
                            </main>
                        }

                        {clickedItemInx ===2 &&
                            <main>
                                <div>
                                    <Survey isCampaign={true} campaign={campaign}/>
                                </div>
                            </main>
                        }
                    </div>
                </main>
            </Drawer>
        </>
    )
}

export default CampaignsDrawer;