import React from 'react';
import { Drawer, Table, Avatar} from 'antd';
import { APP_URL } from '../../config/axios'
import { AxiosError } from 'axios';
import { useAuthContext } from '../../context/AuthContext';
import CheckOutlined from '@ant-design/icons/lib/icons/CheckOutlined';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import { Spinners } from '..';

type DrawerProps = {
    visible: boolean
    setVisible: Function
    employee: any
}

const PendingReviewDrawer = ({ visible, setVisible, employee }: DrawerProps) => {
    const { authKey } = useAuthContext();
    const [pendingReviews, setPendingReviews] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const getDisplayPic = (record) => {
        if (record.profilePic !== null) { return String(record.profilePic); }
        else { return `https://ui-avatars.com/api/?name=${record.firstName + ' ' + record.lastName}&background=59394A&color=fff&size=256` }
    }

    const pendingReviewTableData = [
        {
            dataIndex: 'profilePic',
            width: 90,
            key: 'profilePic',
            render: (text, record) => <div><Avatar className='table-avatar-mobile' src={getDisplayPic(record)} /></div>
        },
        {
            title: <div className="user-table-head">Name</div>,
            dataIndex: 'firstName',
            // width: 250,
            key: 'firstName',
            render: (text, record) => <div>{text} {record.lastName}</div>
        },
        {
            title: <div className="user-table-head">Email</div>,
            dataIndex: 'email',
            // width: 350,
            key: 'email',
            ellipses: true
        },
        {
            title: <div className="user-table-head">Survey</div>,
            dataIndex: 'surveyTitle',
            key: 'surveyTitle',
            // width: 350,
            ellipses: true
        },

        {
            dataIndex: 'completed',
            key: 'completed',
            title: <div className="user-table-head">Completed</div>,

            render: (t, record) => (
                <>
                    {t === false &&
                        <CloseOutlined style={{ color: 'red' }} />
                    }
                    {
                        t === true &&
                        <div className='completed-review-options'>
                            <CheckOutlined style={{ color: 'green' }} />
                        </div>
                    }
                </>)
        },

    ];

    const getPendingReviews = () => {
        setLoading(true)
        let data = { employeeId: employee.id }
        APP_URL.post("reviewschedule/pendingReviews", data, {
            headers: { "Authorization": `Basic ${authKey}` }
        }).then(rev => {
            if (rev.status === 200) {
                setLoading(false)
                getSurveyResponses(rev.data, Number(employee.id))
            }
        }).catch((err: AxiosError) => {
            if (err.response) {
                setLoading(false)
                console.log(err.message)
            }
        })
    }

    const getSurveyResponses = (revList, currId) => {
        APP_URL.get(`surveyresponse`,
            {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                if (res.status === 200) {
                    revList.map(surv => {

                        //self
                        if (res.data.filter(sr => (sr.reviewerId === currId) && (sr.reviewedId === currId) && (surv.reviewedId === currId) && (surv.reviewScheduleId === sr.reviewScheduleId)).length > 0) {
                            surv.completed = true
                        }
                        else if (res.data.filter(sr => (sr.reviewerId === currId) && (sr.reviewedId === surv.reviewedId) && (surv.reviewScheduleId === sr.reviewScheduleId)).length > 0) {
                            surv.completed = true
                        }
                        else {
                            surv.completed = false
                        }
                    })
                    setPendingReviews(revList)
                }
            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            })
    }

    const onClose = () => {
        setVisible(false)
    }

    React.useEffect(() => {
        if (authKey && employee) {
            getPendingReviews();
            console.log('employee', employee)
        }
    }, [authKey, employee])

    return (
        <Drawer
            placement="right"
            closable={true}
            onClose={onClose}
            visible={visible}
            width={'70%'}
            className='employee-drawer'
        >
            <Spinners.CustomSpinner tip={"Getting reviews"} spinning={loading}>

                <Table
                    className='review-table'
                    style={{ marginTop: '15px' }}
                    // rowClassName={(record, index) => isMobile ? (index % 2 === 0 ? 'table-row-light' : 'table-row-dark') : ''}
                    rowKey="id"
                    columns={pendingReviewTableData}
                    dataSource={pendingReviews}
                />
            </Spinners.CustomSpinner>

        </Drawer>
    );
}

export default PendingReviewDrawer;