import { AxiosError } from 'axios';
import React from 'react';
import { APP_URL } from '../config/axios';
import { User } from '../models/User';

interface IReportContext {
    completedStatusReport?: any[];
    setCompletedStatusReport?: any;
    getCompletedStatus?: any;
    loadingReport?: any;
    setLoadingReport?: any;
}

export const ReportContext = React.createContext<IReportContext>({});

export const useReportContext = () => React.useContext(ReportContext);

export const ReportProvider = ({ children }: any) => {
    const [completedStatusReport, setCompletedStatusReport] = React.useState([]);
    const [loadingReport, setLoadingReport] = React.useState(false);

    const getCompletedStatus = (authKey: string) => {
        localStorage.setItem('authKey', authKey)
        setLoadingReport(true)
        APP_URL.get(`reviewschedule/getCompleteReviews`,
            {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then(user => {
                setCompletedStatusReport(user.data)
            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            }).finally(() => {
                setLoadingReport(false)
            })
    }

    return (
        <ReportContext.Provider value={{ completedStatusReport, setCompletedStatusReport, getCompletedStatus, loadingReport, setLoadingReport }}>
            {children}
        </ReportContext.Provider>
    );
};
