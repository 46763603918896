import React, { useEffect }from 'react';
import { Modal, Button, Input, Select, DatePicker, message } from 'antd';
import { APP_URL } from '../../config/axios';
import './style.less'
import { AxiosError } from 'axios';
import { useAuthContext } from '../../context/AuthContext';
import { useReviewContext } from '../../context/ReviewsContext';
import Upload  from '../Inputs/Upload';
import { Spinners } from '../';

type ModalProps = {
    visible: boolean
    setVisible: Function
}

interface IColumnName {
    ordinal: number;
    columnName: string;
}

interface IColMapping {
    [key: string]:number
}

const ExcelUploadModal = ({ visible, setVisible}: ModalProps) => {
    const [confirmLoading, setConfirmLoading] = React.useState<boolean>(false);
    const { authKey } = useAuthContext();
    const { globalOrg } = useReviewContext();
    const [columnNames, setColumnNames] = React.useState<IColumnName[]>([]);
    const [excelFile, setExcelFile] = React.useState(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [loadingTip, setLoadingTip] = React.useState<string>("Getting columns");
    const [colMapping, setColMapping] = React.useState<IColMapping>({});
    const [uploadedFile, setUploadedFile] = React.useState<boolean>(false);

    const { Option } = Select;

    const mapColumns = (fieldName, ordinal) => {
        let tempColMap = { ...colMapping }
        tempColMap[String(fieldName)] = Number(ordinal)
        setColMapping(tempColMap)
    }

    const uploadExcelGetColumns = async (file ) =>{
        setUploadedFile(true);
        setLoading(true);
        let formData = new FormData(); 
        formData.append('OrgId', globalOrg.id);
        formData.append('UserId', '1342');
        formData.append('File', file.target.files[0]);

        setExcelFile(file.target.files[0]);

        APP_URL.post("employee/uploadExcel", formData, {
            headers:
                { "Authorization": `Basic ${authKey}` }
        }
        ).then(res => {
            setColumnNames(res.data);
            setLoading(false);
        }).catch((err: AxiosError) => {
            message.error("Something went wrong uploading the excel file");
            setLoading(false);
        })
    }

    const addEmployeesFromExcel = async (file, columns, userId, orgId )=>{
        setLoadingTip("Adding employees from Excel");
        setLoading(true);
        const hasMinColumns = Object.keys(colMapping).length > 3;
        if (hasMinColumns){ 
            let formData = new FormData(); 
            formData.append('File', file);
            formData.append('DataColumns', JSON.stringify(columns));
            formData.append('UserId', userId);
            formData.append('OrgId', orgId);

            APP_URL.post("employee/returnEmployeesList", formData, {
                headers:
                    { "Authorization": `Basic ${authKey}` }
            }
            ).then(res => {
                message.success('Employees list fetched successfully!');
                startAddingEmployees(res.data);
            }).catch((err: AxiosError) => {
                message.error("Something went wrong adding employees file");
                setLoading(false);
            })
        }
        else{ 
            if (uploadedFile === false){
                return message.error('Please upload an Excel file!')
            }
            else{
                return message.error('Please select all required columns!')
            }
        }
    }

    const startAddingEmployees = (employeesList) => {
        employeesList.map((emp, count) => {
            var loadingTipString = "Adding " + (count +1) + " of " + employeesList.length;
            setLoadingTip(loadingTipString);
            let employeeData = {
                firstName: emp.firstname,
                lastName: emp.lastname,
                email: emp.email,
                managerId: null,
                roleId: null,
                startDate: emp.startDate,
                active: false,
                deptId: null,
                orgId: globalOrg.id,
                userTypeId: 1
            }

            APP_URL.post("employee", employeeData, {
                headers:
                    { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                
                if ((count + 1) === employeesList.length){
                    message.success(`${count+1} Employees added successfully`);
                    setLoading(false);
                    setVisible(false);
                    setConfirmLoading(false)
                    setExcelFile(null);
                    setUploadedFile(false);
                }
            }).catch((err: AxiosError) => {
                if ((count + 1)  === employeesList.length){
                    message.error("Something went wrong adding the employees");
                    setVisible(false);
                    setConfirmLoading(false)
                    setExcelFile(null);
                    setUploadedFile(false);
                }
            })

        })
    }

    const handleOk = async () => {
        addEmployeesFromExcel(excelFile, colMapping, '1000', globalOrg.id);
    };  

    const handleCancel = () => {
        setVisible(false);
        setConfirmLoading(false)
        setExcelFile(null);
        setUploadedFile(false);
    };

    return (
        <>
            <Modal
                title="Add Employees Using Excel"
                visible={visible}
                className="data-input-modal"
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText={"Tribe added"}
                footer={[
                    <Button key="back" className="cancel-btn" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" className="reg-btn" type="primary" loading={confirmLoading} onClick={()=>handleOk()}>
                        Continue
                    </Button>,
                ]}
            >
                <Spinners.CustomSpinner tip={loadingTip} spinning={loading}>
                    <div>
                        {columnNames.length<1 && 
                            <div onChange={(e) => uploadExcelGetColumns(e)}>
                                <Upload placeholder={"Click here to upload"} />
                            </div>
                        }

                        {columnNames.length>0 &&
                            <div className='user-name'>
                                <div className='contain-excel-modal-header'> Please select the corresponding Excel Columns below: </div>

                                <div className='contain-button-and-label'>
                                    <div className='user-name'>
                                        Employee First Name Column
                                    </div>
                                    <Select onChange={(e) => mapColumns("FirstName", e)} size={"large"} className='select-input' placeholder="Excel Columns"  >
                                        {columnNames?.map((col, i) => (
                                            <Option value={col.ordinal}>{col.columnName}</Option>
                                        ))}
                                    </Select>
                                </div>

                                <div className='contain-button-and-label'>
                                    <div className='user-name'>
                                        Employee Last Name Column
                                    </div>
                                    <Select onChange={(e) => mapColumns("LastName", e)}  size={"large"} className='select-input' placeholder="Excel Columns"  >
                                        {columnNames?.map((col, i) => (
                                            <Option value={col.ordinal}>{col.columnName}</Option>
                                        ))}
                                    </Select>
                                </div>

                                <div className='contain-button-and-label'>
                                    <div className='user-name'>
                                        Employee Email Column
                                    </div>
                                    <Select onChange={(e) => mapColumns("Email", e)} size={"large"} className='select-input' placeholder="Excel Columns"  >
                                        {columnNames?.map((col, i) => (
                                            <Option value={col.ordinal}>{col.columnName}</Option>
                                        ))}
                                    </Select>
                                </div>

                                <div className='contain-button-and-label'>
                                    <div className='user-name'>
                                        Employee Start Date Column
                                    </div>
                                    <Select onChange={(e) => mapColumns("StartDate", e)}  size={"large"} className='select-input' placeholder="Excel Columns"  >
                                        {columnNames?.map((col, i) => (
                                            <Option value={col.ordinal}>{col.columnName}</Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>

                        }
                    </div>
                </Spinners.CustomSpinner>
            </Modal>
        </>
    )
}

export default ExcelUploadModal;
