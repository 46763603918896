import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { pages } from '../pages';
import { Layouts } from '../utils/enums';

const Landing = () => {
  const getRoutes = (defaultRoutes: any[]) =>
    defaultRoutes.map(prop => {
      if (prop.layout === Layouts.Landing) {
        return (
          <Route
            path={prop.path}
            exact={prop.exact}
            component={prop.component}
            key={`${prop.path}`}
          />
        );
      }
      return null;
    });

  return <Switch>{getRoutes(pages)}</Switch>;
};

export default Landing;
