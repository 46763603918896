import axios from 'axios';
import { config } from 'process';

export const APP_URL = axios.create({
  baseURL: 'https://bridgelabsperformanceapp.azurewebsites.net/api/',
  // auth: {"password":"", "username":""}
});
export const APP_LOCAL_URL = axios.create({
  baseURL: 'https://localhost:5001/api/'
});

// APP_URL.get("userauth/authorize-user", { headers: { "Authorization": btoa("email:authsystemid") } })