import React from 'react';
import { Layouts } from '../../utils/enums';
import { Analytics } from './analytics';
import { Survey } from './survey';
import { UserManagement } from './user-management';
import { Home } from './home';
import { Campaigns } from './campaigns';

import { AiOutlineClockCircle } from 'react-icons/ai';
import { RiHome5Line } from 'react-icons/ri';
import { RiSurveyLine } from 'react-icons/ri';
import { HiOutlineUsers } from 'react-icons/hi';
import { BsGraphUp } from 'react-icons/bs';
import { RiBarChartLine}  from 'react-icons/ri';
import ReviewSchedule from './review-schedule';

export const admin_pages = [

    {
        path: '/home',
        name: 'Dashboard',
        exact: true,
        icon: <RiHome5Line className='menu-icon' />,
        component: () => <Home />,
        layout: Layouts.Admin
    },
    {
        path: '/review-schedule',
        name: 'Reviews',
        exact: true,
        icon: <AiOutlineClockCircle className='menu-icon' />,
        component: (props) => <ReviewSchedule {...props} />,
        layout: Layouts.Admin
    },
    {
        path: '/user-management',
        name: 'User Management',
        exact: true,
        icon: <HiOutlineUsers className='menu-icon' />,
        component: () => <UserManagement />,
        layout: Layouts.Admin
    },
    { 
        path: '/survey-design',
        name: 'Survey Design',
        exact: true,
        icon: <RiSurveyLine className='menu-icon' />,
        component: () => <Survey isCampaign={false} campaign={null} />,
        layout: Layouts.Admin
    },
    {
        path: '/analytics',
        name: 'Reports',
        exact: true,
        icon: <BsGraphUp className='menu-icon' />,
        component: (props) => <Analytics {...props} />,
        layout: Layouts.Admin
    },
    {
        path: '/campaigns',
        name: 'Campaigns',
        exact: true,
        icon: <RiBarChartLine className='menu-icon' />,
        component: () => <Campaigns />,
        layout: Layouts.Admin
    },
]