import { EllipsisOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Divider, Button, message, Checkbox, Table } from "antd";
import moment from 'moment'
import React from "react";
import './style.less';
import { Modals, Buttons } from "../../../components";
import LogoPlaceholer from '../../../assets/logo-placeholder.png';
import { useAuthContext } from "../../../context/AuthContext";
import { APP_URL } from '../../../config/axios'
import { AxiosError } from 'axios';
import { useReviewContext } from '../../../context/ReviewsContext';

const ReviewSchedule = ({ history }) => {
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [scheduleModalVisible, setScheduleModalVisible] = React.useState(false);
    const [tableLoading, setTableLoading] = React.useState(false);
    const [schedules, setSchedules] = React.useState([])
    const [selectedSchedule, setSelectedSchedule] = React.useState(null)
    const { authKey } = useAuthContext();
    const { globalOrg } = useReviewContext();

    const getReviewSchedules = () => {
        APP_URL.get(`reviewschedule/org/${globalOrg.id}`, {
            headers: { "Authorization": `Basic ${authKey}` }
        }).then((res) => {
            setSchedules(res.data)
        }).catch(err => {
            console.log(err.message);
        })
    }
    const activateReview = (reviewSchedule: any, active: boolean) => {
        setTableLoading(true)
        reviewSchedule.active = !active
        console.log(reviewSchedule)
        APP_URL.put(`reviewschedule/${reviewSchedule.id}`, reviewSchedule,
            {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                getReviewSchedules()
            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            }).finally(() => {
                setTableLoading(false)
            })
    }

    React.useEffect(() => {
        if (authKey && globalOrg) {
            getReviewSchedules()
        }
    }, [authKey, globalOrg])


    React.useEffect(() => {
        if (selectedSchedule) {
            getReviewSchedules()
        }
    }, [selectedSchedule])

    const reviewColumns = [
        {
            title: <div className="user-table-head">KPI</div>,
            dataIndex: 'title',
            key: 'title',
        },

        {
            title: <div className="user-table-head">Active</div>,
            dataIndex: 'active',
            key: 'active',
            render: (val, record) =>
                <div>
                    <Checkbox onChange={() => activateReview(record, val)} checked={val} > Active </Checkbox>
                </div>
        },

        {
            title: <div className="user-table-head">Next Review Date</div>,
            dataIndex: 'date',
            key: 'date',
            render: (val, record) => (moment(val).format("DD MMM YYYY"))
        },

        {
            title: <div className="user-table-head">First Review Date</div>,
            dataIndex: 'initialReviewDate',
            key: 'initialReviewDate',
            render: (val, record) => (moment(val).format("DD MMM YYYY"))
        },

        {
            title: <div className="user-table-head">Actions</div>,
            dataIndex: 'id',
            key: 'id',
            render: (test, row) =>
                <div>
                    <Button onClick={() => { setSelectedSchedule(row); setScheduleModalVisible(true); }} className="update-btn">Update</Button>

                </div>
        },

    ];

    return (
        <div id="organisations">

            {/* <Card
                hoverable
                className='create-review-schedule-card'
                style={{ width: 200 }}
                onClick={() => setIsModalVisible(true)}
            >
                <PlusOutlined style={{ fontSize: 45 }} />
                <div className='review-schedule-card-text' style={{ marginTop: '16px' }}>Create Scheduled Review</div>
            </Card> */}
            <span onClick={() => setIsModalVisible(true)}>
                <Buttons.Primary text="Create Scheduled Review" />
            </span>
            <Table
                className='review-table'
                // rowClassName={(record, index) => isMobile ? (index % 2 === 0 ? 'table-row-light' : 'table-row-dark') : ''}
                rowKey="id"
                columns={reviewColumns}
                dataSource={schedules}
                loading={tableLoading}
            />
            <Modals.CreateReviewScheduleModal setSchedule={setSelectedSchedule} visible={isModalVisible} setVisible={setIsModalVisible} />
            <Modals.ScheduleModal visible={scheduleModalVisible} setReviewSchedule={getReviewSchedules} reviewSchedule={selectedSchedule} setVisible={setScheduleModalVisible} />
        </div>
    );
}

export default ReviewSchedule;