import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { admin_pages } from '../pages/admin-pages';
import { Layouts } from '../utils/enums';
import { Layout } from 'antd';
import { Headers, Siders } from '../components';
import { AiOutlineMenuUnfold, AiOutlineMenuFold } from 'react-icons/ai'

const Admin = ({ history }) => {
  const { Content } = Layout;
  const [collapsed, setCollapsed] = React.useState(true);

  const getRoutes = (defaultRoutes: any[]) =>
    defaultRoutes.map(prop => {
      if (prop.layout === Layouts.Admin) {
        return (
          <Route
            path={prop.layout + prop.path}
            exact={prop.exact}
            component={prop.component}
            key={prop.path}
          />
        );
      }
      return null;
    });

  return (
    <main id="admin">
      <Headers.Mobile routes={admin_pages} />
      <Layout>
        <Siders.Main collapsed={collapsed} routes={admin_pages} />
        <Content className="content">
          <div style={{ display: 'flex', backgroundColor: '#fff' }}>
            {React.createElement(collapsed ? AiOutlineMenuUnfold : AiOutlineMenuFold, {
              className: 'menu-trigger',
              onClick: () => setCollapsed(!collapsed)
            })}
            {/* <AiOutlineMenuUnfold onClick={() => setCollapsed(!collapsed)} className='menu-trigger' /> */}
              <Headers.Main history={history} />
          </div>
          <Switch>{getRoutes(admin_pages)}</Switch>
        </Content>
      </Layout>
    </main>
  );
};

export default Admin;
