import { Input } from 'antd';
import React from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai'

import './style.less';

const Upload = ({ placeholder }) => {
    return (
        <Input className='upload-button'  type="file" placeholder={placeholder} size={"large"} prefix={<AiOutlineCloudUpload />} />
    );
};

export default Upload;
