import React from 'react';
import { AxiosError } from 'axios';
import { Modal, Button, Input, message, Form} from 'antd';
import { APP_URL } from '../../config/axios';
import { FormInstance } from 'antd/lib/form';
import './style.less';
import { useReviewContext } from '../../context/ReviewsContext';
import { useAuthContext } from '../../context/AuthContext';
import moment from 'moment'


type ModalProps = {
    visible: boolean,
    setVisible: Function
}

const CreateCampaignModal = ({ visible, setVisible}: ModalProps) => {
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [formValues, setFormValues] = React.useState<any>();
    const { authKey } = useAuthContext();
    const formRef = React.useRef<FormInstance>();
    const { globalUserEmp, globalOrg } = useReviewContext();

    const handleOk = () => {
        formRef.current.submit();
    };

    const insertCampaign = () => {

        setConfirmLoading(true);

        let campaignData = {
            name: formValues?.name,
            active: true,
            employeeId: globalUserEmp.id,
            orgId: globalOrg.id,
            surveyId: 7, //change this at some point
            dateCreated: moment()
        }
        
        APP_URL.post("campaign", campaignData, {
            headers:
                { "Authorization": `Basic ${authKey}` }
        }).then(res => {
            message.success("Campaign addedd successfully");
            setConfirmLoading(false);
            setVisible(false);
        }).catch((err: AxiosError) => {
            setConfirmLoading(false);
            console.log(err)
            console.log(err.message)
            message.error("There was an error adding campaign")
        })
    };

    const handleCancel = () => {
        setVisible(false);
        setConfirmLoading(false)
    };

    const onFinishFailed = () => {
        message.error("Something went wrong adding the campaign")
    }

    const validateMessages = {
        required: "'${name}' is required!",
    };

    return (
        <>
            <Modal
                destroyOnClose={true}
                width={"40%"}
                title="Create Campaign"
                visible={visible}
                className="data-input-modal"
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText={"Campaign created"}
                footer={[
                    <Button key="back" className="cancel-btn" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" className="reg-btn" type="primary" loading={confirmLoading} onClick={handleOk}>
                        Save
                    </Button>,
                ]}
            >
                <Form
                    ref={formRef}
                    name="basic"
                    onValuesChange={(prevValues, values) => { setFormValues(values) }}
                    validateMessages={validateMessages}
                    initialValues={{ remember: false }}
                    onFinish={insertCampaign}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        name="name"
                        rules={[{ required: true, }]}
                    >
                        <Input placeholder={'Campaign Name: e.g Virgin Mobile Simcards'}  onChange={(val) => (val.target.value)} className='modal-data-input'></Input>
                    </Form.Item>

                </Form>
            </Modal>
        </>
    )
}

export default CreateCampaignModal;
