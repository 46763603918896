import React from 'react';
import { Layouts } from '../../utils/enums';
import { Profile } from './profile';
import { Reviews } from './reviews';
import { Reports } from './reports';
import { Team } from './team';
import { Home } from './home';
import { RiFileList3Line } from 'react-icons/ri';
import { VscLoading } from 'react-icons/vsc';
import { MdPeopleOutline } from 'react-icons/md';
import { RiHome5Line } from 'react-icons/ri';
import { CgProfile } from 'react-icons/cg';

export const employee_pages = [
    {
        path: '/home',
        name: 'Home',
        exact: true,
        icon: <RiHome5Line className='menu-icon' />,
        component: () => <Home />,
        layout: Layouts.Employeee
    },
    {
        path: '/reviews',
        name: 'Pending Reviews',
        exact: true,
        icon: <VscLoading className='menu-icon' />,
        component: () => <Reviews />,
        layout: Layouts.Employeee
    },
    {
        path: '/reports',
        name: 'Reports',
        exact: true,
        icon: <RiFileList3Line className='menu-icon' />,
        component: () => <Reports />,
        layout: Layouts.Employeee
    },
    {
        path: '/team',
        name: 'Team Management',
        exact: true,
        icon: <MdPeopleOutline className='menu-icon' />,
        component: () => <Team />,
        layout: Layouts.Employeee
    },
    {
        path: '/profile',
        name: 'Profile',
        exact: true,
        icon: <CgProfile className='menu-icon' />,
        component: () => <Profile />,
        layout: Layouts.Employeee
    },
];
