import React from 'react';
import './style.less';
import { useAuthContext } from '../../context/AuthContext';
import { APP_URL } from '../../config/axios'
import { AxiosError } from 'axios';
import { Avatar, Input, Select, Rate, List, Collapse } from 'antd';
import { AiOutlineSearch } from 'react-icons/ai';

const ManagersPerformance = () => {
    const { authKey } = useAuthContext();
    const [employees, setEmployees] = React.useState([]);
    const [searchQuery, setSearchQuery] = React.useState('')
    const [isSearching, setIsSearching] = React.useState(false)
    const [reviewSchedule, setReviewSchedule] = React.useState([]);
    const [selectedReviewSchedule, setSelectedReviewSchedule] = React.useState(0);
    const [managers, setManagers] = React.useState([]);
    const [filteredManagers, setFilteredManagers] = React.useState([]);
    const [managersResults, setManagersResults] = React.useState([])
    const [questionResponses, setQuestionResponses] = React.useState([]);
    const [filteredQuestionResponses, SetFilteredQuestionResponses] = React.useState([]);

    const getDisplayPic = (emp) => {
        if (emp.profilePic !== null) { return String(emp.profilePic); }
        else { return `https://ui-avatars.com/api/?name=${emp.firstname + ' ' + emp.lastname}&background=59394A&color=fff&size=256` }
    }

    const handleSelectReviewSchedule = (index) => {
        setSelectedReviewSchedule(index);
    }

    const getActiveReviewSchdedules = () => {
        APP_URL.get(`reviewschedule`,
            {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                setReviewSchedule(res.data);
            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            })
    }

    const getEmployees = () => {
        APP_URL.get(`employee`,
            {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then(user => {
                setEmployees(user.data)
            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            })

    }

    const getQuestionResponses = () => {
        APP_URL.get(`questionResponse`,
            {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then(qs => {
                setQuestionResponses(qs.data)
            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            })

    }

    React.useEffect(() => {
        getQuestionResponses()
        getEmployees()
        getActiveReviewSchdedules()
    }, [authKey]);

    React.useEffect(() => {
        if (employees.length > 0) {
            let _managers = Array.from(new Set(employees.map(item => item.managerId)));
            setManagers(employees.filter(emp => _managers.includes(emp.id)));
        }

    }, [employees])

    const handleSearch = (e) => {
        setSearchQuery(e.target.value)
    }

    React.useEffect(() => {
        if (String(searchQuery).length > 0) {
            setIsSearching(true)
            const emp = filteredManagers.length > 0 ? filteredManagers : managers;

            // Filter item in user files, mimicking the "LIKE %%" in an sql query
            setManagersResults(
                emp.filter(item => item.firstname.toLowerCase().indexOf(searchQuery) >= 0
                    || item.lastname.toLowerCase().indexOf(searchQuery) >= 0)
            );
        } else {
            setIsSearching(false)
        }
    }, [searchQuery])

    const getEmpResponseRating = () => {
        let _questionResponses = questionResponses.filter(x => x.questionId === 84 && x.reviewScheduleId === reviewSchedule[selectedReviewSchedule].id)
        _questionResponses = _questionResponses.map(x => {
            x.reviewer = employees.find(emp => +emp.id === +x.reviewerId);
            x.rating = JSON.parse(x.answers)[0];
            return x;
        });
        SetFilteredQuestionResponses(_questionResponses);
    }

    const getManagersRating = (mangerId) => {
        let _quesRes = filteredQuestionResponses.filter(x => x.reviewedId === +mangerId);
        let _totalRating = _quesRes.reduce<number>((total, currentValue) => total += currentValue.rating, 0);
        let rating = _totalRating / _quesRes.length;
        return isNaN(rating) ? 0 : rating;
    }

    React.useEffect(() => {
        if (questionResponses.length > 0 && managers.length > 0 && reviewSchedule.length > 0) {
            getEmpResponseRating()
        }
    }, [questionResponses, managers, reviewSchedule])


    const header = (manager) => {
        return (
            <div><Avatar size="small" className='table-avatar' src={getDisplayPic(manager)} /> {manager.firstname} {manager.lastname}</div>
        );
    }

    return (
        <>
            <Select style={{ marginTop: '10px', marginBottom: '10px' }} size={'large'} onChange={handleSelectReviewSchedule} defaultValue={0} className='survey-select survey-select-staff-reviews'>
                {reviewSchedule.map((rs, inx) => (
                    <Select.Option key={inx} value={inx} >{rs.title}</Select.Option>
                ))}
            </Select>
            <Input className='search' value={searchQuery} onChange={(e) => handleSearch(e)} placeholder={"Search by name"} size={"large"} prefix={<AiOutlineSearch />} />

            <Collapse accordion style={{ marginBottom: '16px' }}>
                <Collapse.Panel
                    disabled
                    className='title-collpase-header '
                    style={{ cursor: 'auto !important' }}
                    key={'header'}
                    header='Manager'
                    extra='Overall Rating'
                    showArrow={false}
                />
                {(isSearching ? managersResults : managers).map((mn) => {
                    return (
                        <Collapse.Panel
                            key={mn.id}
                            header={header(mn)}
                            extra={
                                <>
                                    <Rate className='q-rate-manager-report' allowHalf disabled value={getManagersRating(mn.id)} />
                                    <span className='rating-text-manager'>{(getManagersRating(mn.id).toFixed(1))}</span>
                                </>
                            }
                        >
                            <List
                                itemLayout="horizontal"
                                dataSource={filteredQuestionResponses.filter(x => x.reviewedId === +mn.id)}
                                renderItem={item => (
                                    <List.Item
                                        extra={<Rate className='q-rate-emp-res' disabled defaultValue={item.rating} />}
                                    >
                                        <List.Item.Meta
                                            avatar={<Avatar size="small" className='table-avatar' src={getDisplayPic(item.reviewer)} />}
                                            title={<div>{item.reviewer.firstname} {item.reviewer.lastname}</div>}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Collapse.Panel>
                    );
                })}
            </Collapse>
        </>
    );
};

export default ManagersPerformance;
