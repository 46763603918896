import React from 'react';
import { Modal, Button, Input, message, List } from 'antd';
import { APP_URL } from '../../config/axios'
import { Multiple, Tag, Text, Rating } from '../../models/Questions'
import './style.less'
import { AxiosError } from 'axios';
import { useAuthContext } from '../../context/AuthContext';
import { MdAdd } from 'react-icons/md';

type ModalProps = {
    visible: boolean,
    setVisible: Function,
    question: any,
}

const QuestionModal = ({ visible, setVisible, question, }: ModalProps) => {
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [questionContent, setQuestionContent] = React.useState(null);
    const [questionTitle, setQuestionTitle] = React.useState(null);
    const [optTitle, setOptTitle] = React.useState('');
    const [questionItems, setQuestionItems] = React.useState([]);
    const [questionType, setQuestionType] = React.useState(-1);
    const [questionView, setQuestionView] = React.useState(<> </>);

    const { authKey } = useAuthContext();

    const putQuestion = () => {
        setConfirmLoading(true);

        // console.log(JSON.stringify(question.questionContent))
        questionContent.items = questionItems;
        questionContent.title = questionTitle;
        question.questionContent = JSON.stringify(questionContent)
        // console.log(question)

        if (questionTitle.length < 1) {
            message.warning("question title")
        }
        else {
            APP_URL.put(`question/${question.id}`, question, {
                headers:
                    { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                setVisible(false);
                setConfirmLoading(false);
                message.success("Question updated successfully")
            }).catch((err: AxiosError) => {
                console.log(err.message)
                message.error("There was an error updating question")
            })
        }
    }

    const addQuestionItems = () => {
        if (optTitle.length > 0) {
            setQuestionItems(items => [...items, optTitle])
            setOptTitle('')
        }
    }

    const deleteQuestionItems = (qItem) => {
        setQuestionItems(questionItems.filter(item => item !== qItem));
        setOptTitle('')
    }

    React.useEffect(() => {
        if (question !== null) {
            // console.log(questionItems)
            if (question.questionTypeId === 3) {
                setQuestionView(generateMultiQuestion(questionContent))
            }
            else if (question.questionTypeId === 4) {
                setQuestionView(generateTagQuestion(questionContent))
            }
        }

    }, [questionItems])

    React.useEffect(() => {
        if (question !== null) {
            if (question.questionTypeId === 3) {
                setQuestionView(generateMultiQuestion(questionContent))
            }
            else if (question.questionTypeId === 4) {
                setQuestionView(generateTagQuestion(questionContent))
            }
        }

    }, [optTitle])

    React.useEffect(() => {
        if (question !== null) {
            if (question.questionTypeId === 1) {
                setQuestionView(generateTextQuestion(questionContent))
            }
            if (question.questionTypeId === 2) {
                setQuestionView(generateRateQuestion(questionContent))
            }
            if (question.questionTypeId === 3) {
                setQuestionView(generateMultiQuestion(questionContent))
            }
            else if (question.questionTypeId === 4) {
                setQuestionView(generateTagQuestion(questionContent))
            }
            else if (question.questionTypeId === 5) {
                setQuestionView(generateHeaderQuestion(questionContent))
            }
        }

    }, [questionTitle])

    React.useEffect(() => {
        if (question !== null) {
            setQuestionType(question.questionTypeId)
            if (question.questionTypeId === 1) {
                let questContent: Text = JSON.parse(String(question.questionContent));
                setQuestionContent(questContent)
                setQuestionTitle(questContent.title)
                setQuestionView(generateTextQuestion(questContent))
            }
            else if (question.questionTypeId === 2) {
                let questContent: Rating = JSON.parse(String(question.questionContent));
                setQuestionContent(questContent)
                setQuestionTitle(questContent.title)
                setQuestionView(generateRateQuestion(questContent))
            }
            else if (question.questionTypeId === 3) {
                let questContent: Multiple = JSON.parse(String(question.questionContent));
                // console.log(questContent)
                setQuestionContent(questContent)
                setQuestionTitle(questContent.title)
                setQuestionItems(questContent.items)
                setQuestionView(generateMultiQuestion(questContent))
            }
            else if (question.questionTypeId === 4) {
                let questContent: Tag = JSON.parse(String(question.questionContent));
                setQuestionContent(questContent)
                setQuestionTitle(questContent.title)
                setQuestionItems(questContent.items)
                setQuestionView(generateTagQuestion(questContent))
            }

            else if (question.questionTypeId === 5) {
                let questContent: Text = JSON.parse(String(question.questionContent));
                setQuestionContent(questContent)
                setQuestionTitle(questContent.title)
                setQuestionView(generateHeaderQuestion(questContent))
            }
        }

    }, [question])

    const generateTextQuestion = (q: Text) => (
        <>
            <div>Title:</div> <Input value={questionTitle} className='modal-data-input' onChange={(val) => setQuestionTitle(val.target.value)}></Input>
        </>
    )
    const generateHeaderQuestion = (q: Text) => (
        <>
            <div>Title:</div> <Input value={questionTitle} className='modal-data-input' onChange={(val) => setQuestionTitle(val.target.value)}></Input>
        </>
    )
    const generateRateQuestion = (q: Rating) => (
        <>
            <div>Title:</div> <Input value={questionTitle} className='modal-data-input' onChange={(val) => setQuestionTitle(val.target.value)}></Input>
        </>
    )

    const generateTagQuestion = (q: Tag) => (
        <>
            <div>Title:</div> <Input value={questionTitle} className='modal-data-input' onChange={(val) => setQuestionTitle(val.target.value)}></Input>
            <div>
                <div className="items-head">
                    <span className='new-opt'> New Options</span>
                    <Input placeholder={"Add new items"} value={optTitle} onChange={(val) => setOptTitle(val.target.value)} style={{ width: "70%" }} className='modal-data-input'></Input>
                    <Button onClick={() => addQuestionItems()} className='add-item-btn' icon={<MdAdd style={{ fontSize: '1.7rem' }} />} />
                </div>
                <List size="small"
                    header={<div>Question options</div>}
                    bordered
                    dataSource={questionItems}
                    renderItem={item =>
                        <List.Item className='quest-list-item' >
                            {item}
                            <Button onClick={() => deleteQuestionItems(item)} className='delete-q'>Delete</Button>
                        </List.Item>
                    } ></List>
            </div>
        </>
    )

    const generateMultiQuestion = (q: Multiple) => (
        <>
            <div>Title:</div> <Input value={questionTitle} className='modal-data-input' onChange={(val) => setQuestionTitle(val.target.value)}></Input>
            <div>
                <div className="items-head">
                    <span className='new-opt'> New Options</span>
                    <Input placeholder={"Add new items"} value={optTitle} onChange={(val) => setOptTitle(val.target.value)} style={{ width: "70%" }} className='modal-data-input'></Input>
                    <Button onClick={() => addQuestionItems()} className='add-item-btn' icon={<MdAdd style={{ fontSize: '1.7rem' }} />} />
                </div>
                <List size="small"
                    header={<div>Question options</div>}
                    bordered
                    dataSource={questionItems}
                    renderItem={item =>
                        <List.Item className='quest-list-item' >
                            {item}
                            <Button onClick={() => deleteQuestionItems(item)} className='delete-q'>Delete</Button>
                        </List.Item>
                    } ></List>
            </div>
        </>
    )

    const handleOk = () => {
        putQuestion()
    };

    const handleCancel = () => {
        setVisible(false);
        setConfirmLoading(false)
    };



    return (
        <>
            <Modal
                width={"40%"}
                title="Edit Question"
                visible={visible}
                className="data-input-modal"
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText={"Question updated"}
                footer={[
                    <Button key="back" className="cancel-btn" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" className="reg-btn" type="primary" loading={confirmLoading} onClick={handleOk}>
                        Save
                    </Button>,
                ]}
            >
                <div>
                    {questionView}
                </div>
            </Modal>
        </>
    )
}

export default QuestionModal;
