import React from 'react';

interface IReviewContext {
    globalPendingReviews?: any[];
    setGlobalPendingReview?: any;
    globalUserEmp?: any;
    setGlobalUserEmp?: any;
    globalReports?: any[];
    setGlobalReports?: any;
    globalSchedules?: any[];
    setGlobalShedules?: any;
    globalOrg?: any;
    setGlobalOrg?: any;
}

export const ReviewContext = React.createContext<IReviewContext>({});

export const useReviewContext = () => React.useContext(ReviewContext);

export const ReviewProvider = ({ children }: any) => {

    const [globalPendingReviews, setGlobalPendingReview] = React.useState([])
    const [globalUserEmp, setGlobalUserEmp] = React.useState(null)
    const [globalSchedules, setGlobalShedules] = React.useState([])
    const [globalReports, setGlobalReports] = React.useState([])
    const [globalOrg, setGlobalOrg] = React.useState([])

    return (
        <ReviewContext.Provider value={{
            globalPendingReviews, setGlobalPendingReview,
            globalUserEmp, setGlobalUserEmp,
            globalReports, setGlobalReports,
            globalOrg, setGlobalOrg,
            globalSchedules, setGlobalShedules
        }}
        >
            {children}
        </ReviewContext.Provider>
    );
};