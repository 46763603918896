import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { useAuthContext } from './context/AuthContext';
import { useReportContext } from './context/ReportContext';
import { APP_URL } from './config/axios'
import Admin from './layout/Admin';
import Employee from './layout/Employee';
import PrintableReview from './components/Reports/PrintableReview'
import { AxiosError } from 'axios';

import Landing from './layout/Landing';
import { Home } from './pages/employee-pages/home';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-207973303-1');  

function logPageView() { 
    ReactGA.set({ page: window.location.pathname + window.location.search });
    ReactGA.pageview(window.location.pathname + window.location.search);
}

const PrivateRoute = ({ component: Component, ...rest }) => {

  const { setIsLogged, isLogged, setAuthKey, authenticateUser, currentUser } = useAuthContext();
  const { getCompletedStatus, completedStatusReport } = useReportContext();

  React.useEffect(() => {
    let isLogged = false;

    if (localStorage.getItem("authKey")) {

      let authKey = localStorage.getItem("authKey");
      isLogged = true
      setIsLogged(isLogged)
      setAuthKey(authKey)

      if (completedStatusReport.length < 1) {
        console.log('get reports');

        getCompletedStatus(authKey)

      }

      if (!currentUser) {

        //currentUser state has been reset, probably due to a refresh
        APP_URL.get("userauth/authorize-user",
          {
            headers:
              { "Authorization": `Basic ${authKey}` }
          })
          .then(res => {
            if (res.status === 200) {
              authenticateUser(authKey, res.data);
              console.log("fetched currentUser")
            }
          })
          .catch((err: AxiosError) => {
            if (err.response) {
              if (err.response.status === 401) {
                console.log("unathorized")
              }
              else {
                console.log(err.message)
              }
            }
          })
      }
    }

    else {
      isLogged = false
      setIsLogged(isLogged)
    }
  }, [])

  return (
    <Route
      {...rest}
      render={props =>
        isLogged ? (
          <Component {...props} />
        ) : (
          <div></div>
        )
      }
    />
  );
};

const Routes = () => {

  React.useEffect( () => { 
    logPageView()
}, [window.location.pathname]);

return(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={props => <Landing />} />
      <Route path="/viewprintablereview" component={PrintableReview} render={props => <PrintableReview {...props} />} />
      <Route path="/join/:orgCode" render={props => <Landing />} />

      <PrivateRoute path="/admin" component={(props) => <Admin history={props.history} />} />
      <PrivateRoute path="/employee" component={(props) => <Employee history={props.history} />} />
    </Switch>
  </BrowserRouter>

)};

export default Routes;
