import React from 'react';
import { Modal, Button, Input, message } from 'antd';
import { APP_URL } from '../../config/axios'
import './style.less'
import { AxiosError } from 'axios';
import { useAuthContext } from '../../context/AuthContext';

type ModalProps = {
    visible: boolean,
    setVisible: Function,
    setMegaTribe: Function,
    megaTribe: any
}

const MegaTribeModal = ({ visible, setVisible, setMegaTribe, megaTribe }: ModalProps) => {
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [megaTribeName, setMegaTribeName] = React.useState('');
    const [tribeDesc, setTribeDesc] = React.useState('');
    const { authKey } = useAuthContext();

    const postMegaTribe = () => {
        
        setConfirmLoading(true);

        let megatribeData = {
            name: megaTribeName,
            description: tribeDesc,
        }

        if (megaTribeName.length < 1) {
            message.warning("megatribe name")
        } else {
            APP_URL.post("megatribe", megatribeData, {
                headers:
                    { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                setMegaTribe(res.data)
                setVisible(false);
                setConfirmLoading(false);
                message.success("Mega tribe added successfully")
                setMegaTribeName("")
                setTribeDesc("")
            }).catch((err: AxiosError) => {
                console.log(err.message)
                message.error("There was an error adding mega tribe")
            })
        }
    }
    const putMegaTribe = () => {

        setConfirmLoading(true);
        megaTribe.name = megaTribeName
        megaTribe.description = tribeDesc

        if (megaTribeName.length < 1) {
            message.warning("megatribe name")
        }
        else {
            APP_URL.put(`megatribe/${megaTribe.id}`, megaTribe, {
                headers:
                    { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                setMegaTribe(null)
                setVisible(false);
                setConfirmLoading(false);
                message.success("Mega tribe updated successfully")
                setMegaTribeName("")
                setTribeDesc("")
            }).catch((err: AxiosError) => {
                console.log(err.message)
                message.error("There was an error updating mega tribe")
            })
        }
    }

    const handleOk = () => {
        if (megaTribe === null) {
            postMegaTribe()
        }
        else {
            putMegaTribe()
        }
    };

    React.useEffect(() => {
        if (megaTribe !== null) {
            setMegaTribeName(megaTribe.name)
            setTribeDesc(megaTribe.description)

        }
        else {
            setMegaTribeName('')
            setTribeDesc('')
        }
    }, [megaTribe])

    const handleCancel = () => {
        setVisible(false);
        setConfirmLoading(false)
    };

    return (
        <>
            <Modal
                title="Create a Mega Tribe"
                visible={visible}
                className="data-input-modal"
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText={"Mega tribe added"}
                footer={[
                    <Button key="back" className="cancel-btn" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" className="reg-btn" type="primary" loading={confirmLoading} onClick={handleOk}>
                        Continue
                    </Button>,
                ]}
            >
                <div>
                    <div>Megatribe Name:</div> <Input className='modal-data-input' value={megaTribeName} onChange={(val) => setMegaTribeName(val.target.value)} placeholder="The Room"></Input>
                    <div>Description:</div> <Input className='modal-data-input' value={tribeDesc} onChange={(val) => setTribeDesc(val.target.value)} placeholder="The room members"></Input>
                </div>

            </Modal>
        </>
    )
}

export default MegaTribeModal;
