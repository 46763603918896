import React from 'react';
import { Drawer, Rate, Avatar, Radio, List, Input, Table, Tabs, Button, message } from 'antd';
import { useAuthContext } from '../../context/AuthContext';
import { Bar } from 'react-chartjs-2';
import ReactQuill from 'react-quill';
import 'react-quill//dist/quill.snow.css';
import { APP_URL } from '../../config/axios';
import { AxiosError } from 'axios';
import { useReviewContext } from '../../context/ReviewsContext';

type DrawerProps = {
    visible: boolean
    setVisible: Function
    selectedResponse: any
    name: string
    reviewComment: any
    reviewedId: number
    inverse: boolean
}


const ChartReportDrawer = ({ visible, setVisible, selectedResponse, name, reviewComment, inverse, reviewedId }: DrawerProps) => {
    const { authKey } = useAuthContext();
    const [isPosting, setIsPosting] = React.useState(false);
    const [rating, setRating] = React.useState(0);
    const [comment, setComment] = React.useState('');
    const [deliverables, setDeliverables] = React.useState([]);
    const { globalUserEmp } = useReviewContext();

    const col = [
        {
            title: <div className="user-table-head"></div>,
            dataIndex: 'pic',
            key: 'pic',
            render: (text, record) => <div><Avatar size="default" className='table-avatar' src={text} /></div>
        },

        {
            title: <div className="user-table-head"></div>,
            dataIndex: 'otherPic',
            key: 'otherPic',
            render: (text, record) => <div><Avatar size="default" className='table-avatar' src={text} /></div>
        },

        {
            title: <div className="user-table-head">{selectedResponse?.reviewerInfo?.firstname || selectedResponse?.reviewerInfo?.firstName} Answers</div>,
            dataIndex: 'otherAnswer',
            // width: 350,
            key: 'otherAnswer',
        }

    ];

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            // ['link', 'image'],
            ['clean']
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ];

    const { TextArea } = Input;

    const handleReactQuillChange = (value) => {
        setComment(value)
    }

    const handleRatingChange = (rating) => {
        setRating(rating)
    }

    const onClose = () => {
        setVisible(false)
    }


    const getDeliverables = (empId) => {
        // setLoading(true)
        // setLoadingText("Getting KPIs")
        setDeliverables([])
        APP_URL.get(`deliverableResponse/employee/${empId}`,
            {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then(delivRes => {
                setDeliverables(delivRes.data)

            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            }).finally(() => {
                // setLoading(false)
            })
    }

    // React.useEffect(() => {
    //    console.log("deliverables: ", deliverables)
    // }, [deliverables])

    const getDisplayPic = (emp) => {
        if (emp.profilePic !== null) { return String(emp.profilePic); }
        else { return `https://ui-avatars.com/api/?name=${emp.firstname + ' ' + emp.lastname}&background=59394A&color=fff&size=256` }
    }

    React.useEffect(() => {
       
        if (reviewComment) {
            if (reviewComment.rating) {
                setRating(reviewComment.rating)
            } else {
                setRating(0)
            }

            if (reviewComment.comment) {
                setComment(reviewComment.comment);
            } else {
                setComment('')
            }
        }
    }, [reviewComment])


    React.useEffect(() => {
       
        if (reviewedId) {
            console.log("reviewedId: ", reviewedId)
        getDeliverables(reviewedId);
        }
    }, [reviewedId])

    const postReviewComment = () => {
        if (comment.length > 0 && rating > 0) {
            const hide = message.loading("Adding your review summary")
            setIsPosting(true);
            reviewComment.rating = rating
            reviewComment.comment = comment

            if (reviewComment?.shouldUpdate) {
                APP_URL.put(`reviewcomment/${reviewComment.id}`, reviewComment, {
                    headers:
                        { "Authorization": `Basic ${authKey}` }
                }).then(res => {
                    hide();
                    message.success("Response recorded successfully");
                }).catch((err: AxiosError) => {
                    console.log(err.message)
                    message.error("There was an error adding your response")
                }).finally(() => {
                    setIsPosting(false);
                })
            } else {
                APP_URL.post("reviewcomment", reviewComment, {
                    headers:
                        { "Authorization": `Basic ${authKey}` }
                }).then(res => {
                    hide();
                    message.success("Response recorded successfully");
                }).catch((err: AxiosError) => {
                    console.log(err.message)
                    message.error("There was an error adding your response")
                }).finally(() => {
                    setIsPosting(false);
                })
            }
        } else {
            message.error('Please provide a comment and a rating')
        }
    }

    const parseString = (questionTitle: string) => {
        if (inverse) {
            let userName = selectedResponse?.reviewerInfo?.firstname || selectedResponse?.reviewerInfo?.firstName
            return questionTitle.replace(/{employee}/g, userName).replace(/{manager}/g, userName)
        } else {
            return questionTitle.replace(/{employee}/g, globalUserEmp?.firstname).replace(/{manager}/g, globalUserEmp?.firstname)

        }
    }

    const renderDelResponse = () => {
        return (
            deliverables.map(del => (
                <div className='del-resp'>
                    <div className='list-flex'>
                        <div className='del-rate-contain'>
                            <Avatar className='rate-vatar' style={{ marginRight: '5px' }} src={globalUserEmp?.profilePic} />
                            <div className="rating-title">
                                <span className='text-response'>"{del.title}</span>
                            </div>
                        </div>
                    </div>
                    <div className='del-rating-response'>
                        <Rate disabled value={del.rating} className='green-rating' />

                        <span className='text-response'>"{del.justification}</span>
                    
                    </div>


                </div>
            ))

        )
    }

    const renderElement = (q: any, inx: number) => {
        if (q.questionTypeId == 1) {
            let response = (q.questionResponse)
            return (
                <div >
                    <div className='text-flex'>
                        <Avatar style={{ marginRight: '5px' }} src={globalUserEmp?.profilePic} />
                        <span className='text-response'>"{response[0]}"</span>
                    </div>
                </div>
            )
        }

        else if (q.questionTypeId == 2) {
            let response = (q.questionResponse)
            // let response2 = selectedResponse?.responseJson[inx].questionResponse
            return (
                <div className='list-flex'>
                    <div style={{ borderRight: '1px solid #000' }} className='rate-contain'>
                        <Avatar className='rate-vatar' style={{ marginRight: '5px' }} src={globalUserEmp?.profilePic} />
                        <Rate disabled value={response[0]} className='green-rating' />
                    </div>
                </div>
            )

        }

        else if (q.questionTypeId == 3) {
            let response = JSON.parse(q?.questionResponse)
            return (
                <Radio.Group defaultValue={response[0]} className='multi-choice'>
                    {response?.map((m) => (
                        <Radio value={m}>{m}</Radio>
                    ))}
                </Radio.Group>
            )

        }
        else if (q.questionTypeId == 4) {
            let response = (q.questionResponse)
            let response2 = selectedResponse?.responseJson[inx].questionResponse
            return (
                <div className='list-flex'>
                    <div style={{ width: '45%' }}>
                        <List dataSource={response}

                            renderItem={item => (
                                <List.Item>
                                    <Avatar style={{ marginRight: '5px' }} src={globalUserEmp?.profilePic} /> {item}
                                </List.Item>
                            )}
                        />
                    </div>

                </div>
            )
        }
        else if (q.questionTypeId == 7) {
            return <span className='q-header'></span>
        }

        else {
            return <button>404</button>
        }
    }


    return (
        <>
            <Drawer
                placement="right"
                closable={true}
                onClose={onClose}
                visible={visible}
                width={'80%'}
                className='employee-drawer'
            >
                <Tabs defaultActiveKey="1" className='chart-report-tabs'>
                    <Tabs.TabPane tab="Comparison View" key="1" >
                        <div className='flex-response'>
                            {
                                inverse ?
                                    <p className='info'>
                                        How you reviewed {name}
                                    </p>
                                    :

                                    <p className='info'>
                                        How {name} reviewed you
                                    </p>

                            }

                            {selectedResponse?.responseJson.map((q, i) => (
                                <div key={i} className='question' >
                                    <div className='question-top'>
                                        <div className='question-title' style={{ fontSize: '1.4rem', fontWeight: 'bold' }} > {parseString(q.questionTitle)}</div>
                                        <span className='question-controls'></span>
                                    </div>
                                    <div className="question-element">
                                        {renderElement(q, i)}
                                    </div>
                                </div>
                            ))}
                            {/* {currentSelfReview &&
                                <div className='compare-review'>
                                    {
                                        !inverse ?
                                        :
                                            <p className='info'>
                                                Your review of {currentSelfReview?.reviewerInfo?.firstname} compared against {currentSelfReview?.reviewerInfo.firstname}'s self review
                                            </p>
                                    }
                                    ))}
                                </div>
                            } */}

                        </div>

                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Deliverables" key="2">
                            <>
                                {renderDelResponse()}

                            </>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Review Summary" key="3">
                        <div className='review-instructions'>1. Please provide a summary of the review conversation (include points of alignment and points of misalignment)</div>
                        <ReactQuill
                            onChange={handleReactQuillChange}
                            value={comment || ''}
                            style={{ height: '40vh' }}
                            modules={modules}
                            formats={formats}
                        />
                        <div className='review-instructions' style={{ marginTop: '70px' }}>2) What is the final rating you and the team member have agreed on? Please include a summary on why this rating is an accurate reflection of their performance</div>
                        <div className='final-rating'>
                            <Rate className='q-rate' value={rating} onChange={handleRatingChange} />
                        </div>
                        <Button disabled={isPosting} className='view-btn' onClick={postReviewComment} style={{ marginTop: '20px' }}>Submit Review</Button>


                    </Tabs.TabPane>
                </Tabs>
            </Drawer>
        </>
    )
}

export default ChartReportDrawer;