import React from 'react';
import './style.less';
import { useAuthContext } from '../../context/AuthContext';
import { useReportContext } from '../../context/ReportContext';
import { APP_URL } from '../../config/axios'
import { AxiosError } from 'axios';
import { Avatar, Button, Table, Input, Select, Checkbox, Switch } from 'antd';
import { Drawers, Spinners } from '../'
import { AiOutlineSearch } from 'react-icons/ai';
import CheckOutlined from '@ant-design/icons/lib/icons/CheckOutlined';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';

const CompletedStatusReport = () => {
    // const [employees, setEmployees] = React.useState([]);
    const [employee, setEmployee] = React.useState([]);
    const [employeesResults, setEmployeesResults] = React.useState([])
    const [filteredEmployees, setFilteredEmployees] = React.useState([])
    const [isSearching, setIsSearching] = React.useState(false)
    const [pendingDrawerVisible, setPendingDrawerVisible] = React.useState(false)
    const { authKey, currentUser } = useAuthContext();
    const [searchQuery, setSearchQuery] = React.useState('')
    const [reviewSchedule, setReviewSchedule] = React.useState([]);
    const [selectedReviewSchedule, setSelectedReviewSchedule] = React.useState(0);
    const [completedStatuses, setCompletedStatuses] = React.useState([]);
    const [filterComplete, setFilterComplete] = React.useState(false);
    const [filterNotComplete, setFilterNotComplete] = React.useState(false);
    const { completedStatusReport, loadingReport } = useReportContext();

    const right: FixedType = 'right';


    const getDisplayPic = (emp) => {
        if (emp.profilePic !== null) { return String(emp.profilePic); }
        else { return `https://ui-avatars.com/api/?name=${emp.firstname + ' ' + emp.lastname}&background=59394A&color=fff&size=256` }
    }

    const hasCompletedStatus = (empId) => {
        const _completeStatus = completedStatuses.filter(x => x.employeeId === empId && x.reviewScheduleId === reviewSchedule[selectedReviewSchedule]?.id);
        return _completeStatus.length > 0;
    }

    const getActiveReviewSchdedules = () => {
        APP_URL.get(`reviewschedule`,
            {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                setReviewSchedule(res.data);
            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            })
    }

    React.useEffect(() => {
        if (String(searchQuery).length > 0) {
            setIsSearching(true)
            const emp = filteredEmployees.length > 0 ? filteredEmployees : completedStatusReport;

            // Filter item in user files, mimicking the "LIKE %%" in an sql query
            setEmployeesResults(
                emp.filter(item => item.firstname.toLowerCase().indexOf(searchQuery) >= 0
                    || item.lastname.toLowerCase().indexOf(searchQuery) >= 0)
            );
        } else {
            setIsSearching(false)
        }
    }, [searchQuery])

    const handleSelectReviewSchedule = (index) => {
        setSelectedReviewSchedule(index)
    }

    const handleFilterByCompleted = (e) => {
        if (e.target.checked) {
            setFilterComplete(e.target.checked)
            setFilterNotComplete(!e.target.checked)
            const emp = completedStatusReport.filter(x => x.completed === true);
            setFilteredEmployees(emp);

        } else {
            setFilterComplete(e.target.checked)
            setFilteredEmployees([]);
        }
    }
    const handleFilterByNotCompleted = (e) => {
        if (e.target.checked) {
            setFilterNotComplete(e.target.checked)
            setFilterComplete(!e.target.checked)
            const emp = completedStatusReport.filter(x => x.completed === false);
            setFilteredEmployees(emp);
        } else {
            setFilterNotComplete(e.target.checked)
            setFilteredEmployees([]);
        }
    }

    // const getEmployees = () => {
    //     APP_URL.get(`reviewschedule/getCompleteReviews`,
    //         {
    //             headers: { "Authorization": `Basic ${authKey}` }
    //         }).then(user => {
    //             setEmployees(user.data)
    //         }).catch((err: AxiosError) => {
    //             if (err.response) {
    //                 console.log(err.message)
    //             }
    //         })

    // }

    const getCompletedStatuses = () => {
        APP_URL.get(`completestatus`,
            {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                setCompletedStatuses(res.data);
            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            })
    }

    // const updateReviewComment = () => {
    //     APP_URL.post(`reviewschedule/updateCompleteReviews`, {},
    //         {
    //             headers: { "Authorization": `Basic ${authKey}` }
    //         });
    // }

    const handleSearch = (e) => {
        setSearchQuery(e.target.value)
    }

    React.useEffect(() => {
        // updateReviewComment()
        // getEmployees()
        // getCompletedStatuses();
        getActiveReviewSchdedules();

    }, [authKey])

    const activeUsersColumns = [
        {
            title: <div className="user-table-head">Name</div>,
            dataIndex: 'firstname',
            width: 250,
            key: 'firstname',
            ellipsis: true,
            sorter: {
                compare: (a, b) => a.firstname.localeCompare(b.firstname),
                multiple: 1
            },
            render: (text, record) => <div><Avatar size="small" className='table-avatar' src={getDisplayPic(record)} /> {text} {record.lastname}</div>
        },
        {
            title: <div className="user-table-head">Email</div>,
            dataIndex: 'email',
            width: 300,
            key: 'email',
        },
        {
            title: <div className="user-table-head">Completed</div>,
            dataIndex: 'completed',
            key: 'completed',
            render: (text, record) => (
                <div>
                    { text ?
                        <CheckOutlined style={{ color: 'green' }} />
                        :
                        <CloseOutlined style={{ color: 'red' }} />
                    }
                </div>
            )
        },
        // {
        //     title: <div className="user-table-head">Actions</div>,
        //     dataIndex: 'id',
        //     fixed: right,
        //     key: 'id',
        //     render: (test, row) =>
        //         <div>
        //             <Button className="update-btn" onClick={(e) => { e.stopPropagation(); setPendingDrawerVisible(true); setEmployee(row); }} >View</Button>
        //         </div>
        // },
    ];

    return (
        <>
            <Spinners.CustomSpinner tip={"Getting reviews"} spinning={loadingReport}>
            <div className='reports-list'>

            </div>
            <div className='user-mgmt-container'>
                <div>
                    <Select style={{ marginTop: '10px', marginBottom: '10px' }} size={'large'} onChange={handleSelectReviewSchedule} defaultValue={0} className='survey-select'>
                        {reviewSchedule.map((rs, inx) => (
                            <Select.Option key={inx} value={inx} >{rs.title}</Select.Option>
                        ))}
                    </Select>
                    <Checkbox style={{ marginLeft: '16px' }} checked={filterComplete} onChange={handleFilterByCompleted} >Filter by Completed</Checkbox>
                    <Checkbox style={{ marginLeft: '16px' }} checked={filterNotComplete} onChange={handleFilterByNotCompleted}>Filter by Not Completed</Checkbox>
                </div>

                <Input className='search' value={searchQuery} onChange={(e) => handleSearch(e)} placeholder={"Search by name"} size={"large"} prefix={<AiOutlineSearch />} />
                {isSearching ?
                    <Table
                        pagination={{
                            current: 1,
                            pageSize: 25,
                        }
                        }
                        className='user-table'
                            defaultExpandAllRows
                        dataSource={employeesResults}
                        rowKey="id" columns={activeUsersColumns} />
                    :
                    <Table
                        className='user-table'
                            defaultExpandAllRows={true}
                            dataSource={filteredEmployees.length > 0 ? filteredEmployees : completedStatusReport}
                        rowKey="id" columns={activeUsersColumns}
                    />
                }
            </div>
                {/* <Drawers.PendingReviewsDrawer visible={pendingDrawerVisible} employee={employee} setVisible={setPendingDrawerVisible} /> */}
            </Spinners.CustomSpinner>
        </>
    );
};

export default CompletedStatusReport;
export declare type FixedType = 'left' | 'center' | 'right';
