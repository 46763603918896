import React from 'react';
import { Drawer, Table, Avatar, Tabs, Card } from 'antd';
import { APP_URL } from '../../config/axios'
import { AxiosError } from 'axios';
import { useAuthContext } from '../../context/AuthContext';

type DrawerProps = {
    visible: boolean
    setVisible: Function
    employee: any
    megaTribes: any[]
    roles: any[]
}

const EmployeeDrawer = ({ visible, setVisible, employee, megaTribes, roles }: DrawerProps) => {
    const [currentEmployee, setCurrentEmployee] = React.useState(null);
    const { authKey } = useAuthContext();
    const { TabPane } = Tabs;
    const onClose = () => {
        setVisible(false)
    }

    const renderMegaTribe = (emp) => {
        if ((megaTribes.filter(mt => mt.id === emp.megatribeId)[0])) {
            return (
                <>
                    {megaTribes.filter(mt => mt.id === emp.megatribeId)[0].name}
                </>
            )
        }

        else {
            return (
                <>
                </>
            )
        }
    }

    React.useEffect(() => {
        if (employee.id) {
            APP_URL.get(`employee/${employee.id}`, {
                headers:
                    { "Authorization": `Basic ${authKey}` }
            }).then(res2 => {
                setCurrentEmployee(res2.data)
            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            })
        }


    }, [employee, authKey])

    const renderRole = (emp) => {
        if ((roles.filter(r => r.id === emp.roleId)[0])) {
            return (
                <>
                    {roles.filter(r => r.id === emp.roleId)[0].roleName}
                </>
            )
        }
        else {
            return (
                <>
                </>
            )
        }
    }

    const getDisplayPic = (emp) => {
        if (emp.profilePic !== null) { return String(emp.profilePic); }
        else { return `https://ui-avatars.com/api/?name=${emp.firstname + ' ' + emp.lastname}&background=59394A&color=fff&size=256` }
    }

    const canReviewTableData = [
        {
            title: <div className="user-table-head">Name</div>,
            dataIndex: 'firstname',
            width: 250,
            key: 'firstname',
            render: (text, record) => <div><Avatar size="small" className='table-avatar' src={getDisplayPic(record)} /> {text} {record.lastname}</div>
        },
        {
            title: <div className="user-table-head">Email</div>,
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: <div className="user-table-head">Role</div>,
            dataIndex: 'roleId',
            key: 'roleId',
            render: (text) => <span>{String(roles.length > 0 && roles.filter(r => r.id === text)[0].roleName)}</span>
        },
        {
            title: <div className="user-table-head">Mega Tribe</div>,
            dataIndex: 'megatribeId',
            key: 'megatribeId',
            render: (text) => <span>{String(megaTribes.length > 0 && megaTribes.filter(mt => mt.id === text)[0].name)}</span>
        },
    ];

    return (
        <>
            <Drawer
                placement="right"
                closable={true}
                onClose={onClose}
                visible={visible}
                width={'50%'}
                className='employee-drawer'
            >
                {currentEmployee &&
                <div className="drawer-root">
                    <div className="employee-info" >
                        <Avatar className='drawer-avatar' src={getDisplayPic(currentEmployee)} />

                        <div className="drawer-emp-details" >
                            <div className='drawer-emp-name'>{currentEmployee.firstname} {currentEmployee.lastname}</div>
                            <div className='drawer-emp-role'>
                                {renderMegaTribe(currentEmployee)} : {renderRole(currentEmployee)}
                            </div>
                        </div>
                    </div>
                    <hr className='drawer-line' />
                    <Tabs className='drawer-tab' defaultActiveKey="1">
                        <TabPane tab="Can Review" key="1">
                            <Table columns={canReviewTableData} dataSource={currentEmployee.inverseManager} />
                        </TabPane>
                        <TabPane tab="Reviewed By" key="2">
                            {currentEmployee.manager ?
                                <Card
                                    style={{ width: 300, margin: 'auto' }}
                                    title={currentEmployee.manager.firstname + ' ' + currentEmployee.manager.lastname}
                                    cover={<img alt='display-pic' width={50} src={getDisplayPic(currentEmployee.manager)} />}  >

                                    <div className='manager-name'>
                                        <span className='label' >email:</span> <span>{currentEmployee.manager.email}</span>
                                    </div>
                                    <div className='manager-name'>
                                        <span className='label'>Mega Tribe:</span> <span>{renderMegaTribe(currentEmployee.manager)}</span>
                                    </div>
                                    <div className='manager-name'>
                                        <span className='label'>Role:</span> <span>{renderRole(currentEmployee.manager)}</span>
                                    </div>
                                </Card>

                                :
                                <div>
                                    This user is not reviewed by anyone
                                </div>
                            }

                            {/* <Table columns={managerTableData} dataSource={[employee.manager]} /> */}
                        </TabPane>
                    </Tabs>

                </div>
                }


            </Drawer>
        </>
    )
}

export default EmployeeDrawer;